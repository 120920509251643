import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-panel",
    {
      attrs: {
        image: _vm.image,
        "is-read-only": _vm.isReadOnly,
        "width-style": _vm.widthStyle,
        "related-url": _vm.relatedUrl,
        "validation-lists": _vm.validationLists,
        "translate-obj": _vm.translateObj,
        "element-author-display": _vm.elementAuthorDisplay,
      },
      on: { close: _vm.closePanel, changeTranslate: _vm.changeTranslate },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("template", { slot: "md-description" }, [_c("div")]),
      _c("template", { slot: "element" }, [
        _c(
          "div",
          [
            _vm.value.circuitBreaker != undefined
              ? _c(
                  VCard,
                  { attrs: { flat: "" } },
                  [
                    _c(VCardText, { staticClass: "panel-title" }, [
                      _vm._v("Associated Aggregate"),
                    ]),
                    _c(
                      VCardText,
                      {
                        staticStyle: {
                          "margin-left": "20px",
                          "margin-top": "-35px",
                        },
                      },
                      [
                        _c(VSwitch, {
                          attrs: { inset: "", label: `Circuit Breaker` },
                          model: {
                            value: _vm.value.circuitBreaker,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "circuitBreaker", $$v)
                            },
                            expression: "value.circuitBreaker",
                          },
                        }),
                        _vm.value.circuitBreaker
                          ? _c(VCheckbox, {
                              staticStyle: { "margin-top": "-10px" },
                              attrs: { label: `Fallback` },
                              model: {
                                value: _vm.value.fallback,
                                callback: function ($$v) {
                                  _vm.$set(_vm.value, "fallback", $$v)
                                },
                                expression: "value.fallback",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
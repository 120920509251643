import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-panel",
    {
      attrs: {
        image: _vm.image,
        "is-read-only": _vm.isReadOnly,
        "width-style": _vm.widthStyle,
        "related-url": _vm.relatedUrl,
        "validation-lists": _vm.validationLists,
        "translate-obj": _vm.translateObj,
        "element-author-display": _vm.elementAuthorDisplay,
      },
      on: { close: _vm.closePanel, changeTranslate: _vm.changeTranslate },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("template", { slot: "t-description-text" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("panelInfo.ExternalDefinitionPanel")) +
            "\n    "
        ),
      ]),
      _c("template", { slot: "t-generation-text" }),
      _c("template", { slot: "t-edit-user" }, [
        _vm.newEditUserImg.length > 0 && _vm.isReadOnly
          ? _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  VChip,
                  {
                    staticStyle: { "font-weight": "bold" },
                    attrs: {
                      small: "",
                      color: "orange",
                      "text-color": "white",
                    },
                    on: {
                      "~click": function ($event) {
                        return _vm.forceEditPanel()
                      },
                    },
                  },
                  [
                    _c(
                      VAvatar,
                      { attrs: { left: "" } },
                      [_c(VIcon, [_vm._v("mdi-lead-pencil")])],
                      1
                    ),
                    _c(VRow, [
                      _c("div", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.newEditUserImg[0].name) +
                            " is now editing..."
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "margin-right": "10px",
                          },
                        },
                        [_vm._v(" ( Click to force editing ) ")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("template", { slot: "element" }, [
        _c(
          "div",
          [
            _c(
              VCard,
              { attrs: { flat: "" } },
              [
                _c(VCardText, { staticClass: "panel-title" }, [
                  _vm._v("Api path"),
                ]),
                _c(
                  VCardText,
                  [
                    _c(VTextField, {
                      attrs: {
                        outlined: "",
                        disabled: _vm.isReadOnly,
                        hint: "맨 처음 '/' 제외한 apiPath를 작성해주세요. (Default '/')",
                      },
                      model: {
                        value: _vm.value.controllerInfo.apiPath,
                        callback: function ($$v) {
                          _vm.$set(_vm.value.controllerInfo, "apiPath", $$v)
                        },
                        expression: "value.controllerInfo.apiPath",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              VCard,
              { attrs: { flat: "" } },
              [
                _c(VCardText, { staticClass: "panel-title" }, [
                  _vm._v("Method"),
                ]),
                _c(
                  VCardText,
                  [
                    _c(VAutocomplete, {
                      staticStyle: {
                        "margin-left": "10px",
                        "margin-right": "10px",
                      },
                      attrs: {
                        items: _vm.restfulList,
                        disabled: _vm.isReadOnly,
                        label: "Method",
                        "persistent-hint": "",
                      },
                      model: {
                        value: _vm.value.controllerInfo.method,
                        callback: function ($$v) {
                          _vm.$set(_vm.value.controllerInfo, "method", $$v)
                        },
                        expression: "value.controllerInfo.method",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "geometry-element",
        {
          attrs: {
            selectable: _vm.selectable,
            movable: _vm.movable,
            resizable: _vm.resizable,
            deletable: _vm.deletable,
            connectable: "",
            id: _vm.value.elementView.id,
            x: _vm.elementCoordinate.x,
            y: _vm.elementCoordinate.y,
            width: _vm.elementCoordinate.width,
            height: _vm.elementCoordinate.height,
            angle: _vm.elementCoordinate.angle,
            customMoveActionExist: _vm.canvas.isCustomMoveExist,
            label: _vm.namePanel,
            _style: {
              "label-angle": _vm.value.elementView.angle,
              "font-weight": "bold",
              "font-size": "16",
            },
          },
          on: {
            "update:id": function ($event) {
              return _vm.$set(_vm.value.elementView, "id", $event)
            },
            "update:x": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "x", $event)
            },
            "update:y": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "y", $event)
            },
            "update:width": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "width", $event)
            },
            "update:height": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "height", $event)
            },
            "update:angle": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "angle", $event)
            },
            customMoveAction: _vm.delayedMove,
            moveShape: _vm.onMoveShape,
            removeShape: _vm.onRemoveShape,
            selectShape: _vm.selectedActivity,
            deSelectShape: _vm.deSelectedActivity,
            dblclick: _vm.openPanel,
            rotateShape: _vm.onRotateShape,
            addedToGroup: _vm.onAddedToGroup,
            "update:label": function ($event) {
              _vm.namePanel = $event
            },
          },
        },
        [
          _vm.movingElement
            ? _c("geometry-rect", {
                attrs: {
                  _style: {
                    "fill-r": 1,
                    "fill-cx": 0.1,
                    "fill-cy": 0.1,
                    "stroke-width": 1.4,
                    stroke: "#ED73B6",
                    fill: "#ED73B6",
                    "fill-opacity": 0.5,
                    r: "1",
                  },
                },
              })
            : _c("geometry-rect", {
                attrs: {
                  _style: {
                    "fill-r": 1,
                    "fill-cx": 0.1,
                    "fill-cy": 0.1,
                    "stroke-width": 1.4,
                    stroke: "#ED73B6",
                    fill: "#ED73B6",
                    "fill-opacity": 1,
                    r: "1",
                  },
                },
              }),
          _c(
            "sub-elements",
            [
              _c("multi-user-status-indicator", {
                attrs: {
                  images: _vm.newEditUserImg,
                  "element-height": _vm.elementCoordinate.height,
                },
              }),
            ],
            1
          ),
          _c(
            "sub-elements",
            [
              _c("geometry-point", {
                attrs: { coordinate: [95, 5], _style: _vm.statusCompleteStyle },
              }),
              _c("text-element", {
                attrs: {
                  "sub-width": "100%",
                  "sub-height": _vm.titleH,
                  "sub-top": 0,
                  "sub-left": 0,
                  text: _vm.value.classReference
                    ? _vm.value.classReference
                    : "<< System >>",
                },
              }),
              _vm.showValidation
                ? _c("image-element", {
                    attrs: {
                      image: _vm.showValidationImg,
                      "sub-width": "20px",
                      "sub-height": "20px",
                      "sub-right": "5px",
                      "sub-bottom": "5px",
                    },
                  })
                : _vm._e(),
              _c("storming-sub-controller", {
                attrs: {
                  type: _vm.value._type,
                  value: _vm.value,
                  isReadOnly: !_vm.isEditElement,
                },
              }),
              _c("sub-controller", {
                attrs: { image: "chatgpt.png" },
                on: { click: _vm.openAutoModeling },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.propertyPanel
        ? _c("external-definition-panel", {
            attrs: {
              isReadOnly: !_vm.isEditElement,
              newEditUserImg: _vm.newEditUserImg,
              image: _vm.image,
              validationLists: _vm.filteredElementValidationResults,
            },
            on: {
              close: _vm.closePanel,
              changedPanelValue: _vm.changedPanelValue,
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
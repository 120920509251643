import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-panel",
    {
      attrs: {
        image: _vm.image,
        isReadOnly: _vm.isReadOnly,
        "width-style": _vm.widthStyle,
        "related-url": _vm.relatedUrl,
        "validation-lists": _vm.validationLists,
        "translate-obj": _vm.translateObj,
        "element-author-display": _vm.elementAuthorDisplay,
      },
      on: { close: _vm.closePanel, changeTranslate: _vm.changeTranslate },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("template", { slot: "t-description-text" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("panelInfo.CommandDefinitionPanel")) +
            "\n    "
        ),
      ]),
      _c("template", { slot: "t-generation-text" }, [
        _vm._v(
          "\n        Commands become the inbound Adaptor implementations and the Port methods of the Aggregate Root according to the Hexagonal / Clean Architecture.\n    "
        ),
      ]),
      _c("template", { slot: "t-edit-user" }, [
        _vm.newEditUserImg.length > 0 &&
        !_vm.value.mirrorElement &&
        _vm.isReadOnly
          ? _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  VChip,
                  {
                    staticStyle: { "font-weight": "bold" },
                    attrs: {
                      small: "",
                      color: "orange",
                      "text-color": "white",
                    },
                    on: {
                      "~click": function ($event) {
                        return _vm.forceEditPanel()
                      },
                    },
                  },
                  [
                    _c(
                      VAvatar,
                      { attrs: { left: "" } },
                      [_c(VIcon, [_vm._v("mdi-lead-pencil")])],
                      1
                    ),
                    _c(VRow, [
                      _c("div", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.newEditUserImg[0].name) +
                            " is now editing..."
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "margin-right": "10px",
                          },
                        },
                        [_vm._v(" ( Click to force editing ) ")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "template",
        { slot: "md-title-side" },
        [
          _c(
            VBtn,
            {
              staticStyle: { "margin-left": "10px", "margin-top": "-12px" },
              attrs: {
                text: "",
                color: "primary",
                disabled: _vm.isReadOnly || !_vm.exampleAvailable,
              },
              on: {
                click: function ($event) {
                  return _vm.openExampleDialog()
                },
              },
            },
            [_vm._v("Examples")]
          ),
          !_vm.exampleAvailable
            ? _c(
                VTooltip,
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              VBtn,
                              _vm._g(
                                _vm._b(
                                  {
                                    staticStyle: {
                                      "margin-left": "-8px",
                                      "margin-top": "-15px",
                                      width: "10px",
                                      height: "10px",
                                    },
                                    attrs: { icon: "" },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c(
                                  VIcon,
                                  { attrs: { color: "grey lighten-1" } },
                                  [_vm._v("mdi-help-circle")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3283573148
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n                The following steps are required to use the 'EXAMPLES'. "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                1. A relationship with the event occurring through the command must be formed. "
                    ),
                    _c("br"),
                    _vm._v("\n                e.g."),
                    _c("br"),
                    _c("img", {
                      attrs: {
                        width: "366",
                        alt: "image",
                        src: "https://github.com/user-attachments/assets/2bf62154-0a8a-4269-867e-996ad90d2947",
                      },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("template", { slot: "element" }, [
        _c(
          "div",
          [
            _vm.openExample
              ? _c("RuleExampleDialog", {
                  on: {
                    closeExampleDialog: function ($event) {
                      return _vm.closeExampleDialog()
                    },
                  },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                })
              : _vm._e(),
            _c(
              VCard,
              { attrs: { flat: "" } },
              [
                _c(
                  VCardText,
                  [
                    _c(
                      VCol,
                      [
                        _c(
                          "span",
                          {
                            staticClass: "panel-title",
                            staticStyle: { "margin-left": "-10px" },
                          },
                          [_vm._v("Associated Aggregate")]
                        ),
                        _c(VTextField, {
                          staticStyle: {
                            "margin-left": "-10px",
                            "margin-top": "-15px",
                            "min-width": "105%",
                          },
                          attrs: {
                            label: "Attach Aggregate && check Name",
                            "single-line": "",
                            disabled: "",
                          },
                          model: {
                            value: _vm.relatedAggregateName,
                            callback: function ($$v) {
                              _vm.relatedAggregateName = $$v
                            },
                            expression: "relatedAggregateName",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "panel-title",
                            staticStyle: { "margin-left": "-10px" },
                          },
                          [_vm._v("Method")]
                        ),
                        _c(
                          VRadioGroup,
                          {
                            staticStyle: { "margin-left": "-13px" },
                            attrs: { disabled: _vm.isReadOnly, row: "" },
                            model: {
                              value: _vm.value.isRestRepository,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "isRestRepository", $$v)
                              },
                              expression: "value.isRestRepository",
                            },
                          },
                          [
                            _c(VRadio, {
                              attrs: { label: "Default Verbs", value: true },
                            }),
                            _c(VRadio, {
                              attrs: { label: "Extend Verb URI", value: false },
                            }),
                          ],
                          1
                        ),
                        _vm.value.isRestRepository
                          ? _c(
                              VCol,
                              [
                                _c(VAutocomplete, {
                                  staticStyle: {
                                    "margin-left": "-22px",
                                    "min-width": "111%",
                                  },
                                  attrs: {
                                    disabled: _vm.isReadOnly,
                                    items: _vm.getRestfulList,
                                    label: "Method",
                                    "persistent-hint": "",
                                  },
                                  model: {
                                    value: _vm.value.restRepositoryInfo.method,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.value.restRepositoryInfo,
                                        "method",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "value.restRepositoryInfo.method",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              VCol,
                              [
                                _c(
                                  VRow,
                                  { staticStyle: { "align-items": "center" } },
                                  [
                                    _c(VTextField, {
                                      staticStyle: {
                                        "margin-left": "-10px",
                                        "min-width": "105%",
                                      },
                                      attrs: {
                                        disabled: _vm.isReadOnly,
                                        label: "API Path",
                                        prefix: `${_vm.elementPrefix}`,
                                      },
                                      model: {
                                        value: _vm.value.controllerInfo.apiPath,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.value.controllerInfo,
                                            "apiPath",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "value.controllerInfo.apiPath",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(VAutocomplete, {
                                  staticStyle: {
                                    "margin-left": "-22px",
                                    "min-width": "111%",
                                  },
                                  attrs: {
                                    disabled: _vm.isReadOnly,
                                    label: "Method",
                                    "persistent-hint": "",
                                    items: _vm.getControllerList,
                                  },
                                  model: {
                                    value: _vm.value.controllerInfo.method,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.value.controllerInfo,
                                        "method",
                                        $$v
                                      )
                                    },
                                    expression: "value.controllerInfo.method",
                                  },
                                }),
                                _c("event-storming-attribute", {
                                  staticStyle: {
                                    "margin-left": "-22px",
                                    "margin-right": "-22px",
                                  },
                                  attrs: {
                                    label: "Request Body",
                                    entities: _vm.entities,
                                    isReadOnly: _vm.isReadOnly,
                                    type: _vm.value._type,
                                    elementId: _vm.value.elementView.id,
                                  },
                                  on: {
                                    "sync-attribute": _vm.syncFromAggregate,
                                  },
                                  model: {
                                    value: _vm.value.fieldDescriptors,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.value,
                                        "fieldDescriptors",
                                        $$v
                                      )
                                    },
                                    expression: "value.fieldDescriptors",
                                  },
                                }),
                              ],
                              1
                            ),
                        _c(
                          "span",
                          {
                            staticClass: "panel-title",
                            staticStyle: { "margin-left": "-10px" },
                          },
                          [_vm._v("Httpie command usages")]
                        ),
                        _c(
                          VRow,
                          { staticStyle: { "align-items": "center" } },
                          [
                            _c(
                              VBtn,
                              {
                                staticStyle: {
                                  "align-self": "start",
                                  "margin-top": "15px",
                                },
                                attrs: { icon: "", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.copyRestRepositoryMethod()
                                  },
                                },
                              },
                              [
                                _c(VIcon, { attrs: { small: "" } }, [
                                  _vm._v(" mdi-content-copy"),
                                ]),
                              ],
                              1
                            ),
                            _c(VTextarea, {
                              staticClass: "mx-2",
                              staticStyle: { "margin-top": "20px" },
                              attrs: { solo: "", rows: "3" },
                              model: {
                                value: _vm.commandExample,
                                callback: function ($$v) {
                                  _vm.commandExample = $$v
                                },
                                expression: "commandExample",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
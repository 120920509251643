import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: _vm.componentKey,
      staticClass: "canvas-panel",
      staticStyle: { left: "0", "margin-top": "-76px", height: "100vh" },
    },
    [
      _c(
        VLayout,
        { attrs: { right: "" } },
        [
          _vm.value
            ? _c(
                "opengraph",
                {
                  ref: "opengraph",
                  attrs: {
                    width: 100000,
                    height: 100000,
                    sliderLocationScale: _vm.sliderLocationScale,
                    "focus-canvas-on-select": "",
                    wheelScalable: "",
                    labelEditable: true,
                    dragPageMovable: _vm.dragPageMovable,
                    enableContextmenu: false,
                    enableRootContextmenu: false,
                    enableHotkeyCtrlC: false,
                    enableHotkeyCtrlV: false,
                    enableHotkeyDelete: false,
                    enableHotkeyCtrlZ: false,
                    enableHotkeyCtrlD: false,
                    enableHotkeyCtrlG: false,
                    slider: true,
                    movable: !_vm.readOnly,
                    resizable: !_vm.readOnly,
                    selectable: true,
                    connectable: !_vm.readOnly,
                    autoSliderUpdate: true,
                    imageBase: _vm.imageBase,
                  },
                  on: { canvasReady: _vm.bindEvents },
                },
                [
                  _vm._l(Object.keys(_vm.value.elements), function (elementId) {
                    return _vm.value.elements &&
                      typeof _vm.value.elements == "object"
                      ? _c(
                          "div",
                          [
                            elementId && _vm.value.elements[elementId]
                              ? _c(
                                  _vm.getComponentByClassName(
                                    _vm.value.elements[elementId]._type
                                  ),
                                  {
                                    ref: elementId,
                                    refInFor: true,
                                    tag: "component",
                                    attrs: {
                                      value: _vm.value.elements[elementId],
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          _vm.value.elements,
                                          elementId,
                                          $event
                                        )
                                      },
                                    },
                                  }
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e()
                  }),
                  _vm._l(
                    Object.keys(_vm.value.relations),
                    function (relationId) {
                      return _vm.value.relations &&
                        typeof _vm.value.relations == "object"
                        ? _c(
                            "div",
                            [
                              relationId && _vm.value.relations[relationId]
                                ? _c(
                                    _vm.getComponentByClassName(
                                      _vm.value.relations[relationId]._type
                                    ),
                                    {
                                      ref: relationId,
                                      refInFor: true,
                                      tag: "component",
                                      attrs: {
                                        value: _vm.value.relations[relationId],
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.value.relations,
                                            relationId,
                                            $event
                                          )
                                        },
                                      },
                                    }
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e()
                    }
                  ),
                ],
                2
              )
            : _vm._e(),
          _c(
            VRow,
            {
              staticClass: "replay-menu-btn",
              attrs: { align: "start", justify: "end" },
            },
            [
              _c(VTextField, {
                staticClass: "replay-project-name",
                attrs: { disabled: "", label: "Project Name" },
                model: {
                  value: _vm.projectName,
                  callback: function ($$v) {
                    _vm.projectName = $$v
                  },
                  expression: "projectName",
                },
              }),
              _vm.showVersionBtn
                ? _c(
                    VBtn,
                    {
                      staticClass:
                        "gs-model-z-index-1 replay-create-version-btn",
                      staticStyle: { "margin-top": "10px" },
                      attrs: { text: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.showDialog()
                        },
                      },
                    },
                    [
                      _c(VIcon, [_vm._v("mdi-content-save")]),
                      _c("div", { staticClass: "replay-create-version" }, [
                        _vm._v("Create Version"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(VFooter, { attrs: { absolute: "" } }, [
            _vm.loading
              ? _c(
                  "div",
                  { staticStyle: { width: "100%", height: "30px" } },
                  [
                    _c(VProgressLinear, {
                      attrs: { indeterminate: "", color: "primary" },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticStyle: { width: "100%", height: "40px" } },
                  [
                    _c(VSlider, {
                      attrs: {
                        min: _vm.slider.min,
                        max: _vm.slider.max,
                        "thumb-label": "always",
                        step: "1",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "thumb-label",
                          fn: function ({ value }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "white-space": "nowrap",
                                    color: "black",
                                  },
                                  on: {
                                    mouseover: _vm.onOverSliderQueue,
                                    mouseleave: _vm.onOverSliderQueue,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.sliderCurrentDate) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "append",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "replay-version-icon",
                                  staticStyle: { "background-color": "red" },
                                },
                                [
                                  _c("Icon", {
                                    staticStyle: {
                                      "background-color": "transparent",
                                      position: "absolute",
                                      left: "5px",
                                      color: "#ffa726",
                                      "margin-top": "-5px",
                                      "z-index": "3",
                                    },
                                    attrs: {
                                      icon: "mdi:flag",
                                      width: "20px",
                                      height: "20px",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "replay-version-icon-tool-tip",
                                  staticStyle: {
                                    "background-color": "black",
                                    opacity: "0.5",
                                    "border-radius": "5px",
                                    "text-align": "center",
                                    "z-index": "3",
                                    position: "absolute",
                                    left: "5px",
                                    bottom: "50px",
                                  },
                                },
                                _vm._l(_vm.detail.title, function (title) {
                                  return _c(
                                    VSlideItem,
                                    { key: title, attrs: { value: title } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            padding: "10px",
                                            color: "white",
                                          },
                                          attrs: { "input-value": _vm.active },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(title) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                              _vm._l(
                                _vm.filteredVersionLists,
                                function (versionItem) {
                                  return _c("div", [
                                    _c("div", {
                                      class: versionItem.isVersion
                                        ? `v-slider__thumb orange lighten-1`
                                        : `v-slider__thumb yellow accent-4`,
                                      style: _vm.markStyle(versionItem),
                                      on: {
                                        click: function ($event) {
                                          return _vm.onClickMark(versionItem)
                                        },
                                        mouseover: function ($event) {
                                          return _vm.onOverSliderMarkLists(
                                            $event,
                                            versionItem
                                          )
                                        },
                                        mouseleave: function ($event) {
                                          return _vm.onOverSliderMarkLists(
                                            $event,
                                            versionItem
                                          )
                                        },
                                      },
                                    }),
                                  ])
                                }
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.slider.current,
                        callback: function ($$v) {
                          _vm.$set(_vm.slider, "current", $$v)
                        },
                        expression: "slider.current",
                      },
                    }),
                  ],
                  1
                ),
          ]),
          _c("model-storage-dialog", {
            attrs: { condition: _vm.condition, showDialog: _vm.dialog },
            on: {
              "update:condition": function ($event) {
                _vm.condition = $event
              },
              backup: _vm.addVersion,
              close: function ($event) {
                return _vm.closeDialog()
              },
            },
          }),
        ],
        1
      ),
      _c(VLayout, [
        _vm.detail.show
          ? _c(
              "div",
              {
                staticStyle: {
                  position: "absolute",
                  right: "0",
                  bottom: "8%",
                  width: "20%",
                  height: "30%",
                },
              },
              [
                _vm.detail.isMark
                  ? _c(
                      VCard,
                      { staticStyle: { width: "100%", height: "100%" } },
                      [
                        _c(
                          VCardTitle,
                          {
                            staticStyle: {
                              "line-height": "1rem",
                              padding: "10px",
                            },
                          },
                          [
                            _c("div", { staticClass: "mark-title" }, [
                              _vm._v("Name: "),
                            ]),
                            _c(
                              VSlideGroup,
                              {
                                attrs: { "show-arrows": "" },
                                model: {
                                  value: _vm.copyItem,
                                  callback: function ($$v) {
                                    _vm.copyItem = $$v
                                  },
                                  expression: "copyItem",
                                },
                              },
                              _vm._l(_vm.detail.title, function (title) {
                                return _c(VSlideItem, {
                                  key: title,
                                  attrs: { value: title },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ active, toggle }) {
                                          return [
                                            _c(
                                              VBtn,
                                              {
                                                staticClass: "mx-2",
                                                attrs: {
                                                  "input-value": active,
                                                  "active-class":
                                                    "primary white--text",
                                                  depressed: "",
                                                  rounded: "",
                                                },
                                                on: { click: toggle },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(title) +
                                                    "\n                            "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              }),
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                },
                              },
                              [
                                _c(
                                  VBtn,
                                  {
                                    attrs: { icon: "", small: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyURL()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      VIcon,
                                      { attrs: { small: "", color: "black" } },
                                      [_vm._v(" mdi-content-copy")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          VCardText,
                          [
                            _c(VRow, { staticStyle: { padding: "6px" } }, [
                              _c("div", { staticClass: "mark-title" }, [
                                _vm._v("Date: "),
                              ]),
                              _vm._v(
                                _vm._s(_vm.detail.date) +
                                  "\n                    "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          VCardText,
                          { staticStyle: { height: "75%", padding: "0px" } },
                          [
                            _c("div", [_vm._v(_vm._s(_vm.detail.subTitle))]),
                            _vm.detail.image
                              ? _c("img", {
                                  attrs: {
                                    contain: "",
                                    "lazy-src":
                                      "https://picsum.photos/id/11/10/6",
                                    "max-height": "170",
                                    "max-width": "303",
                                    width: "100%",
                                    height: "100%",
                                    src: `${_vm.detail.image}`,
                                  },
                                })
                              : _vm._e(),
                            _vm.detail.contents
                              ? _c("div", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.detail.contents) +
                                      "\n                    "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    )
                  : _c(
                      VCard,
                      { staticStyle: { width: "100%", height: "100%" } },
                      [
                        _c(
                          VCardTitle,
                          {
                            staticStyle: {
                              "line-height": "1rem",
                              padding: "10px",
                            },
                          },
                          [
                            _c("div", { staticClass: "mark-title" }, [
                              _vm._v("STATUS: "),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.detail.title) +
                                "\n                "
                            ),
                          ]
                        ),
                        _c(
                          VCardText,
                          [
                            _c(VRow, { staticStyle: { padding: "6px" } }, [
                              _c("div", { staticClass: "mark-title" }, [
                                _vm._v("Date: "),
                              ]),
                              _vm._v(
                                _vm._s(_vm.detail.date) +
                                  "\n                    "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          VCardText,
                          { staticStyle: { height: "60%" } },
                          [
                            _c(
                              VRow,
                              [
                                _c(VAvatar, { attrs: { size: "36" } }, [
                                  _vm.detail.image
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            height: "100%",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: { src: _vm.detail.image },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            height: "100%",
                                          },
                                        },
                                        [
                                          _c(VIcon, [
                                            _vm._v(
                                              "\n                                    mdi-account\n                                "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                ]),
                                _c(VCol, [
                                  _c("div", [
                                    _vm._v(
                                      " " + _vm._s(_vm.detail.subTitle) + " "
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      " " + _vm._s(_vm.detail.contents) + " "
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("modeler-image-generator", { ref: "modeler-image-generator" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "group-element",
        {
          attrs: {
            selectable: _vm.selectable,
            movable: _vm.movable,
            resizable: _vm.resizable,
            deletable: _vm.deletable,
            id: _vm.value.elementView.id,
            x: _vm.elementCoordinate.x,
            y: _vm.elementCoordinate.y,
            width: _vm.elementCoordinate.width,
            height: _vm.elementCoordinate.height,
            customMoveActionExist: _vm.canvas.isCustomMoveExist,
            label: _vm.namePanel,
            _style: {
              "vertical-align": "top",
              "font-weight": "bold",
              "font-size": "16",
            },
          },
          on: {
            "update:id": function ($event) {
              return _vm.$set(_vm.value.elementView, "id", $event)
            },
            "update:x": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "x", $event)
            },
            "update:y": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "y", $event)
            },
            "update:width": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "width", $event)
            },
            "update:height": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "height", $event)
            },
            customMoveAction: _vm.delayedMove,
            moveShape: _vm.onMoveShape,
            removeShape: _vm.onRemoveShape,
            selectShape: _vm.selectedActivity,
            deSelectShape: _vm.deSelectedActivity,
            dblclick: _vm.openPanel,
            addToGroup: _vm.onAddToGroup,
            "update:label": function ($event) {
              _vm.namePanel = $event
            },
          },
        },
        [
          _vm.canvas.isHexagonal
            ? _c(
                "sub-elements",
                [
                  _c(
                    "geometry-element",
                    {
                      attrs: {
                        selectable: !_vm.movingElement,
                        movable:
                          !_vm.canvas.isReadOnlyModel && !_vm.movingElement,
                        resizable:
                          !_vm.canvas.isReadOnlyModel && !_vm.movingElement,
                        deletable:
                          !_vm.canvas.isReadOnlyModel && _vm.isEditElement,
                        id: _vm.elementCoordinate.id,
                        x: _vm.elementCoordinate.x,
                        y: _vm.elementCoordinate.y,
                        width: _vm.elementCoordinate.width,
                        height: _vm.elementCoordinate.height,
                        customMoveActionExist: _vm.canvas.isCustomMoveExist,
                        _style: {
                          "vertical-align": "top",
                          "font-weight": "bold",
                          "font-size": "16",
                        },
                      },
                      on: {
                        "update:id": function ($event) {
                          return _vm.$set(_vm.elementCoordinate, "id", $event)
                        },
                        "update:x": function ($event) {
                          return _vm.$set(_vm.elementCoordinate, "x", $event)
                        },
                        "update:y": function ($event) {
                          return _vm.$set(_vm.elementCoordinate, "y", $event)
                        },
                        "update:width": function ($event) {
                          return _vm.$set(
                            _vm.elementCoordinate,
                            "width",
                            $event
                          )
                        },
                        "update:height": function ($event) {
                          return _vm.$set(
                            _vm.elementCoordinate,
                            "height",
                            $event
                          )
                        },
                        customMoveAction: _vm.delayedMove,
                        moveShape: _vm.onMoveShape,
                        removeShape: _vm.onRemoveShape,
                        selectShape: _vm.selectedActivity,
                        deSelectShape: _vm.deSelectedActivity,
                        dblclick: _vm.openPanel,
                        addToGroup: _vm.onAddToGroup,
                      },
                    },
                    [
                      _c(
                        "sub-elements",
                        [
                          _c("geometry-polygon", {
                            attrs: {
                              vertices: [
                                [25, 0],
                                [75, 0],
                                [100, 50],
                                [75, 100],
                                [25, 100],
                                [0, 50],
                                [25, 0],
                              ],
                              _style: {
                                "fill-r": 1,
                                "fill-cx": 0.1,
                                "fill-cy": 0.1,
                                "stroke-width": 3,
                                stroke: "#000000",
                                fill: "#42A5F5",
                                "fill-opacity": 1,
                                r: "1",
                                "z-index": -1,
                              },
                            },
                          }),
                          _c("geometry-polygon", {
                            attrs: {
                              vertices: [
                                [35, 20],
                                [65, 20],
                                [80, 50],
                                [65, 80],
                                [35, 80],
                                [20, 50],
                                [35, 20],
                              ],
                              _style: {
                                "fill-r": 1,
                                "fill-cx": 0.1,
                                "fill-cy": 0.1,
                                "stroke-width": 1.4,
                                stroke: "#000000",
                                "stroke-dasharray": "- ",
                                fill: "#FFFF00",
                                "fill-opacity": 1,
                                r: "1",
                                "z-index": -1,
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "sub-elements",
                [
                  _vm.movingElement
                    ? _c("geometry-rect", {
                        attrs: {
                          _style: {
                            "fill-r": 1,
                            "fill-cx": 0.1,
                            "fill-cy": 0.1,
                            "stroke-width": 3,
                            stroke: "#000000",
                            fill: "#e3e1e1",
                            "fill-opacity": 0.5,
                            "vertical-align": "top",
                            "font-weight": "bold",
                            "font-size": "16",
                            r: "1",
                          },
                        },
                      })
                    : _c("geometry-rect", {
                        attrs: {
                          _style: {
                            "fill-r": 1,
                            "fill-cx": 0.1,
                            "fill-cy": 0.1,
                            "stroke-width": 3,
                            stroke: "#000000",
                            "fill-opacity": 1,
                            "vertical-align": "top",
                            "font-weight": "bold",
                            "font-size": "16",
                            r: "1",
                          },
                        },
                      }),
                ],
                1
              ),
          _c(
            "sub-elements",
            [
              _c("multi-user-status-indicator", {
                attrs: {
                  images: _vm.newEditUserImg,
                  "element-height": _vm.elementCoordinate.height,
                },
              }),
            ],
            1
          ),
          _vm.value.modelValue.projectId
            ? _c("sub-controller", {
                attrs: { image: "open-in-new.png" },
                on: {
                  click: function ($event) {
                    return _vm.openProject(false)
                  },
                },
              })
            : _vm._e(),
          _vm.value.modelValue.openAPI
            ? _c("sub-controller", {
                attrs: { image: "open-in-new.png" },
                on: {
                  click: function ($event) {
                    return _vm.openProject(true)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.propertyPanel
        ? _c("pbc-panel", {
            attrs: {
              isReadOnly: !_vm.isEditElement,
              newEditUserImg: _vm.newEditUserImg,
              image: _vm.image,
              validationLists: _vm.filteredElementValidationResults,
            },
            on: {
              close: _vm.closePanel,
              changedPanelValue: _vm.changedPanelValue,
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VChip } from 'vuetify/lib/components/VChip';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VTextField,
        _vm._b(
          {
            attrs: { type: "number", label: _vm.label },
            on: {
              change: _vm.change,
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.change.apply(null, arguments)
              },
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          },
          "v-text-field",
          _vm.$attrs,
          false
        )
      ),
      _vm._l(_vm.chips, function (chip) {
        return _c(
          VChip,
          {
            key: chip.label,
            staticClass: "gs-rule-example-chip",
            on: {
              click: function ($event) {
                return _vm.selectChipValue(chip.value)
              },
            },
          },
          [_vm._v("\n        " + _vm._s(chip.label) + "\n    ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { wrap: "" } },
    [
      _c(
        VNavigationDrawer,
        {
          staticStyle: { "z-index": "4" },
          style: _vm.panelWidthStyle,
          attrs: { absolute: "", permanent: "", right: "" },
        },
        [
          _vm._t("root", function () {
            return [
              _c(
                "div",
                [
                  _vm._t("master", function () {
                    return [
                      _vm._t("top", function () {
                        return [
                          _c(
                            VList,
                            {
                              staticClass: "pa-1",
                              staticStyle: { height: "100px" },
                            },
                            [
                              _c(
                                VListItem,
                                [
                                  _vm._t("t-title", function () {
                                    return [
                                      _c(
                                        VCol,
                                        [
                                          _c(
                                            VCol,
                                            { staticStyle: { margin: "0" } },
                                            [
                                              _vm.elementAuthorDisplay
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gs-model-panel-Author",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Author : " +
                                                          _vm._s(
                                                            _vm.elementAuthorDisplay
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            VRow,
                                            [
                                              _c(
                                                VTooltip,
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _vm._t(
                                                              "t-img",
                                                              function () {
                                                                return [
                                                                  _c(
                                                                    VListItemAvatar,
                                                                    {
                                                                      staticClass:
                                                                        "gs-model-panel-sticker",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: _vm.getImage,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              }
                                                            ),
                                                            _c(
                                                              VListItemTitle,
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "headline",
                                                                  staticStyle: {
                                                                    "max-width":
                                                                      "280px",
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._t(
                                                                  "t-title-name",
                                                                  function () {
                                                                    return [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.titleName
                                                                        )
                                                                      ),
                                                                    ]
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    [
                                                      _vm._t(
                                                        "t-generation-text"
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                              _vm._t(
                                                "t-information",
                                                function () {
                                                  return [
                                                    _c(
                                                      VTooltip,
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  VBtn,
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "align-self":
                                                                          "flex-start",
                                                                      },
                                                                    attrs: {
                                                                      icon: "",
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.close()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      VIcon,
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "grey lighten-1",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-close"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  VBtn,
                                                                  _vm._g(
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "align-self":
                                                                            "flex-start",
                                                                        },
                                                                      attrs: {
                                                                        icon: "",
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      VIcon,
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "grey lighten-1",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-information"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  VBtn,
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "align-self":
                                                                          "flex-start",
                                                                      },
                                                                    attrs: {
                                                                      icon: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.executeSetRelatedURL()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      VIcon,
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "grey lighten-1",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi mdi-help-circle"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          [
                                                            _vm._t(
                                                              "t-description-text"
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _c(
                                            VCol,
                                            [_vm._t("t-edit-user")],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                      _vm._t("middle", function () {
                        return [
                          _c(
                            VList,
                            {
                              staticClass: "pt-0",
                              attrs: { dense: "", flat: "" },
                            },
                            [
                              _c(VDivider),
                              _c(
                                "div",
                                [
                                  _c(
                                    VCardText,
                                    [
                                      _vm._t("md-title", function () {
                                        return [
                                          _vm.isValidationLists
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "-17px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VListGroup,
                                                    {
                                                      attrs: {
                                                        value:
                                                          _vm.openValidationLists,
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.openValidationLists =
                                                            !_vm.openValidationLists
                                                        },
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  VIcon,
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "2%",
                                                                      },
                                                                    attrs: {
                                                                      color:
                                                                        _vm
                                                                          .validationLevelIcon[
                                                                          _vm
                                                                            .validationLists[0]
                                                                            .level
                                                                        ].color,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .validationLevelIcon[
                                                                          _vm
                                                                            .validationLists[0]
                                                                            .level
                                                                        ].icon
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  VListItemTitle,
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.representativeValidation
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        3021440218
                                                      ),
                                                    },
                                                    _vm._l(
                                                      _vm.validationLists,
                                                      function (item) {
                                                        return _c(
                                                          VListItem,
                                                          {
                                                            key: item.code,
                                                            staticStyle: {
                                                              "margin-left":
                                                                "5%",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              VIcon,
                                                              {
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "2%",
                                                                },
                                                                attrs: {
                                                                  color:
                                                                    _vm
                                                                      .validationLevelIcon[
                                                                      item.level
                                                                    ].color,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .validationLevelIcon[
                                                                      item.level
                                                                    ].icon
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              VListItemTitle,
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.msg
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            { staticClass: "panel-title" },
                                            [_vm._v("Basic Info")]
                                          ),
                                        ]
                                      }),
                                      _vm._t("md-title-side"),
                                      _c(
                                        "div",
                                        [
                                          _vm._t("md-name-panel", function () {
                                            return [
                                              _c(VTextField, {
                                                staticClass:
                                                  "delete-input-detail",
                                                attrs: {
                                                  error: _vm.value.name == "",
                                                  id: "elementName",
                                                  disabled: _vm.isReadOnly,
                                                  label: "Name",
                                                  autofocus: "",
                                                },
                                                model: {
                                                  value: _vm.value.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.value,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "value.name",
                                                },
                                              }),
                                              _c(VTextField, {
                                                staticClass:
                                                  "delete-input-detail",
                                                attrs: {
                                                  disabled: _vm.isReadOnly,
                                                  label: "Display Name",
                                                },
                                                model: {
                                                  value: _vm.value.displayName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.value,
                                                      "displayName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "value.displayName",
                                                },
                                              }),
                                            ]
                                          }),
                                          _vm._t(
                                            "md-name-panel-translate",
                                            function () {
                                              return [
                                                _vm.translateObj.usedTranslate
                                                  ? _c(
                                                      VCard,
                                                      {
                                                        staticClass:
                                                          "recommendWord-style",
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "10px",
                                                        },
                                                        attrs: {
                                                          outlined: "",
                                                          disabled:
                                                            _vm.isReadOnly,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          VCardText,
                                                          {
                                                            attrs: {
                                                              id: "suggested-words",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeTranslate()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "word.recommendWord"
                                                                  )
                                                                ) +
                                                                " : " +
                                                                _vm._s(
                                                                  _vm
                                                                    .translateObj
                                                                    .translateText
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(VCardText, [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "word.recommendWordDetail"
                                                                )
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ]
                                            }
                                          ),
                                          _vm._t("md-description", function () {
                                            return [
                                              _c(VTextarea, {
                                                attrs: {
                                                  label: "Description",
                                                  disabled: _vm.isReadOnly,
                                                },
                                                model: {
                                                  value: _vm.value.description,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.value,
                                                      "description",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "value.description",
                                                },
                                              }),
                                            ]
                                          }),
                                          _vm._t("generateWithAi"),
                                        ],
                                        2
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                      _vm._t("bottom", function () {
                        return [
                          _vm._t("bo-top"),
                          _vm._t("bo-md"),
                          _vm._t("bo-bo"),
                        ]
                      }),
                    ]
                  }),
                  _vm._t("sub", function () {
                    return [_vm._t("element")]
                  }),
                ],
                2
              ),
            ]
          }),
          _c(
            VDialog,
            {
              attrs: { "max-width": "1400" },
              on: {
                "click:outside": function ($event) {
                  _vm.relatedUrlDialog = false
                },
              },
              model: {
                value: _vm.relatedUrlDialog,
                callback: function ($$v) {
                  _vm.relatedUrlDialog = $$v
                },
                expression: "relatedUrlDialog",
              },
            },
            [
              _c(
                VCard,
                { staticStyle: { height: "800px", overflow: "hidden" } },
                [
                  _c("iframe", {
                    staticStyle: {
                      width: "100%",
                      height: "100%",
                      border: "none",
                      margin: "0",
                      padding: "0",
                      overflow: "hidden",
                    },
                    attrs: { id: "main_frame", src: _vm.relatedUrl },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VChip } from 'vuetify/lib/components/VChip';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VSelect,
        _vm._b(
          {
            attrs: {
              items: _vm.items,
              "item-text": "label",
              "item-value": "value",
              label: _vm.label,
            },
            on: { change: _vm.change },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          },
          "v-select",
          _vm.$attrs,
          false
        )
      ),
      _vm._l(_vm.chips, function (chip) {
        return _c(
          VChip,
          {
            key: chip.label,
            staticClass: "gs-rule-example-chip",
            on: {
              click: function ($event) {
                return _vm.selectChipValue(chip.value)
              },
            },
          },
          [_vm._v("\n        " + _vm._s(chip.label) + "\n    ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-panel",
    {
      attrs: {
        image: _vm.image,
        "is-read-only": _vm.isReadOnly,
        "width-style": _vm.widthStyle,
        "related-url": _vm.relatedUrl,
        "validation-lists": _vm.validationLists,
        "translate-obj": _vm.translateObj,
        "element-author-display": _vm.elementAuthorDisplay,
      },
      on: { close: _vm.closePanel, changeTranslate: _vm.changeTranslate },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "t-img" },
        [
          _c(VListItemAvatar, [
            _c("img", { attrs: { src: _vm.value.imgSrc } }),
          ]),
        ],
        1
      ),
      _c("template", { slot: "t-edit-user" }, [
        _vm.newEditUserImg.length > 0 && _vm.isReadOnly
          ? _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  VChip,
                  {
                    staticStyle: { "font-weight": "bold" },
                    attrs: {
                      small: "",
                      color: "orange",
                      "text-color": "white",
                    },
                    on: {
                      "~click": function ($event) {
                        return _vm.forceEditPanel()
                      },
                    },
                  },
                  [
                    _c(
                      VAvatar,
                      { attrs: { left: "" } },
                      [_c(VIcon, [_vm._v("mdi-lead-pencil")])],
                      1
                    ),
                    _c(VRow, [
                      _c("div", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.newEditUserImg[0].name) +
                            " is now editing..."
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "margin-right": "10px",
                          },
                        },
                        [_vm._v(" ( Click to force editing ) ")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("template", { slot: "md-name-panel-translate" }, [_c("div")]),
      _c("template", { slot: "md-description" }, [_c("div")]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
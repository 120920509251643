import { VBtn } from 'vuetify/lib/components/VBtn';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.generateDone
        ? _c(
            VRow,
            {
              staticStyle: {
                position: "absolute",
                top: "75px",
                right: "35px",
                "z-index": "999",
              },
            },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", color: "primary" },
              }),
              _c(
                "div",
                { staticStyle: { margin: "5px 0px 0px 5px" } },
                [
                  _vm._v("Creating Aggregate... "),
                  _c(
                    VBtn,
                    { attrs: { text: "" }, on: { click: _vm.stop } },
                    [_vm._v("stop")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "geometry-element",
        {
          attrs: {
            selectable: _vm.selectable,
            movable: _vm.movable,
            resizable: _vm.resizable,
            deletable: _vm.deletable,
            connectable: "",
            id: _vm.value.elementView.id,
            x: _vm.elementCoordinate.x,
            y: _vm.elementCoordinate.y,
            width: _vm.elementCoordinate.width,
            height: _vm.elementCoordinate.height,
            angle: _vm.elementCoordinate.angle,
            customMoveActionExist: _vm.canvas.isCustomMoveExist,
            label:
              _vm.getFieldDescriptors || _vm.getQueryParameterDescriptors
                ? ""
                : _vm.getNamePanel,
            _style: {
              "label-angle": _vm.value.elementView.angle,
              "font-weight": "bold",
              "font-size": "16",
            },
          },
          on: {
            "update:id": function ($event) {
              return _vm.$set(_vm.value.elementView, "id", $event)
            },
            "update:x": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "x", $event)
            },
            "update:y": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "y", $event)
            },
            "update:width": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "width", $event)
            },
            "update:height": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "height", $event)
            },
            "update:angle": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "angle", $event)
            },
            customMoveAction: _vm.delayedMove,
            moveShape: _vm.onMoveShape,
            removeShape: _vm.onRemoveShape,
            selectShape: _vm.selectedActivity,
            deSelectShape: _vm.deSelectedActivity,
            dblclick: _vm.openPanel,
            rotateShape: _vm.onRotateShape,
            addedToGroup: _vm.onAddedToGroup,
          },
        },
        [
          _vm.movingElement
            ? _c("geometry-rect", {
                attrs: {
                  _style: {
                    "fill-r": 1,
                    "fill-cx": 0.1,
                    "fill-cy": 0.1,
                    "stroke-width": 1.4,
                    stroke: "#5FC08B",
                    fill: "#5FC08B",
                    "fill-opacity": 0.5,
                    r: "1",
                  },
                },
              })
            : _c("geometry-rect", {
                attrs: {
                  _style: {
                    "fill-r": 1,
                    "fill-cx": 0.1,
                    "fill-cy": 0.1,
                    "stroke-width": 1.4,
                    stroke: "#5FC08B",
                    fill: "#5FC08B",
                    "fill-opacity": 1,
                    r: "1",
                  },
                },
              }),
          _c(
            "sub-elements",
            [
              _c("multi-user-status-indicator", {
                attrs: {
                  images: _vm.newEditUserImg,
                  "element-height": _vm.elementCoordinate.height,
                },
              }),
            ],
            1
          ),
          _c(
            "sub-elements",
            [
              _c("geometry-point", {
                attrs: { coordinate: [95, 5], _style: _vm.statusCompleteStyle },
              }),
              _c("text-element", {
                attrs: {
                  "sub-width": "100%",
                  "sub-height": _vm.titleH,
                  "sub-top": 0,
                  "sub-left": 0,
                  text: _vm.value.classReference
                    ? _vm.value.classReference
                    : "<< ReadModel >>",
                },
              }),
              _vm.value.dataProjection == "query-for-aggregate" &&
              _vm.getQueryParameterDescriptors &&
              !_vm.value.queryOption.useDefaultUri &&
              _vm.value.queryOption.apiPath
                ? _c("text-element", {
                    attrs: {
                      "sub-width": "100%",
                      "sub-height": _vm.subjectHeight,
                      "sub-top": _vm.subjectTop,
                      "sub-left": 0,
                      subStyle: { "font-size": "16px", "font-weight": "bold" },
                      text: _vm.value.queryOption.apiPath,
                    },
                  })
                : _vm.value.dataProjection == "query-for-aggregate" &&
                  _vm.getQueryParameterDescriptors &&
                  !_vm.value.queryOption.useDefaultUri &&
                  !_vm.value.queryOption.apiPath
                ? _c("text-element", {
                    attrs: {
                      "sub-width": "100%",
                      "sub-height": _vm.subjectHeight,
                      "sub-top": _vm.subjectTop,
                      "sub-left": 0,
                      subStyle: { "font-size": "16px", "font-weight": "bold" },
                      text: _vm.getNamePanel,
                    },
                  })
                : _vm.value.dataProjection == "query-for-aggregate" &&
                  _vm.getQueryParameterDescriptors &&
                  _vm.value.queryOption.useDefaultUri
                ? _c("text-element", {
                    attrs: {
                      "sub-width": "100%",
                      "sub-height": _vm.subjectHeight,
                      "sub-top": _vm.subjectTop,
                      "sub-left": 0,
                      subStyle: { "font-size": "16px", "font-weight": "bold" },
                      text: _vm.getNamePanel,
                    },
                  })
                : _vm.value.dataProjection == "cqrs" && _vm.getFieldDescriptors
                ? _c("text-element", {
                    attrs: {
                      "sub-width": "100%",
                      "sub-height": _vm.subjectHeight,
                      "sub-top": _vm.subjectTop,
                      "sub-left": 0,
                      subStyle: { "font-size": "16px", "font-weight": "bold" },
                      text: _vm.getNamePanel,
                    },
                  })
                : _vm._e(),
              _vm.getFieldDescriptors && _vm.value.dataProjection == "cqrs"
                ? _c("text-element", {
                    staticClass: "discStyle",
                    attrs: {
                      "sub-width": "120%",
                      "sub-height": _vm.detailHeight,
                      "sub-top": _vm.detailTop,
                      "sub-left": _vm.detailLeft,
                      subStyle: { "font-size": "12px", "text-anchor": "start" },
                      text: _vm.getFieldDescriptors,
                    },
                  })
                : _vm._e(),
              _vm.getQueryParameterDescriptors &&
              _vm.value.dataProjection == "query-for-aggregate"
                ? _c("text-element", {
                    staticClass: "discStyle",
                    attrs: {
                      "sub-width": "120%",
                      "sub-height": _vm.detailHeight,
                      "sub-top": _vm.detailTop,
                      "sub-left": _vm.detailLeft,
                      subStyle: { "font-size": "12px", "text-anchor": "start" },
                      text: _vm.getQueryParameterDescriptors,
                    },
                  })
                : _vm._e(),
              _vm.showValidation
                ? _c("image-element", {
                    attrs: {
                      image: _vm.showValidationImg,
                      "sub-width": "20px",
                      "sub-height": "20px",
                      "sub-right": "5px",
                      "sub-bottom": "5px",
                    },
                  })
                : _vm._e(),
              _c("storming-sub-controller", {
                attrs: {
                  type: _vm.value._type,
                  value: _vm.value,
                  isReadOnly: !_vm.isEditElement,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.propertyPanel
        ? _c("view-definition-panel", {
            attrs: {
              isReadOnly: !_vm.isEditElement,
              newEditUserImg: _vm.newEditUserImg,
              image: _vm.image,
              validationLists: _vm.filteredElementValidationResults,
              generateDone: _vm.generateDone,
              generator: _vm.generator,
              isPBCModel: _vm.isPBCModel,
            },
            on: {
              close: _vm.closePanel,
              changedPanelValue: _vm.changedPanelValue,
              "update:generateDone": function ($event) {
                _vm.generateDone = $event
              },
              "update:generate-done": function ($event) {
                _vm.generateDone = $event
              },
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.clone.rotate && !_vm.isHexagonal
        ? _c("sub-controller", {
            attrs: { image: "rotate.png" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.rotate()
              },
            },
          })
        : _vm._e(),
      !_vm.isHexagonal && _vm.selectedList
        ? _c("sub-controller", {
            attrs: { image: "content-cut.png" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.cutElement.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      !_vm.isHexagonal && _vm.clipboard
        ? _c("sub-controller", {
            attrs: { image: "content-paste.png" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.pasteElement.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
      !_vm.isHexagonal
        ? _c("sub-controller", {
            attrs: { image: "copy.png" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.copyPaste()
              },
            },
          })
        : _vm._e(),
      _vm.isHexagonal && _vm.type.endsWith("BoundedContext")
        ? _c(
            "div",
            [
              _c("sub-controller", {
                attrs: { image: "../event/aggregate.png" },
                on: { click: _vm.addAggregateElement },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isHexagonal && _vm.type.endsWith("Aggregate")
        ? _c(
            "div",
            [
              _c("sub-controller", {
                attrs: { image: "../event/event.png" },
                on: { click: _vm.addEventElement },
              }),
              _c("sub-controller", {
                attrs: { image: "../event/command.png" },
                on: { click: _vm.addCommandElement },
              }),
              _c("sub-controller", {
                attrs: { image: "../event/policy.png" },
                on: { click: _vm.addPolicyElement },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.value.aggregates && _vm.value.aggregates.length > 0
        ? _c(
            "div",
            [
              _c("sub-controller", {
                attrs: { image: "repair.png" },
                on: { click: _vm.repairBoundedContext },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.canvasType == "cm"
        ? _c(
            "div",
            [
              _c("sub-controller", {
                attrs: { image: "../event/link-box-variant-primary.png" },
                on: { click: _vm.moveToModel },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
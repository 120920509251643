import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    [
      _c(VCardTitle, [_vm._v("BoundedContext Reconstruction Draft")]),
      _c(VCardSubtitle, [
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _vm.defaultGeneratorUiInputData.numberRemainingDDLs > 0
              ? _c("div", [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      _vm._s(
                        _vm.defaultGeneratorUiInputData.numberRemainingDDLs
                      ) + " DDLs remaining..."
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.defaultGeneratorUiInputData.reGenerate
              ? _c("div", [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      _vm._s(
                        _vm.defaultGeneratorUiInputData.boundedContextLists
                      ) + " Re-generating..."
                    ),
                  ]),
                ])
              : _vm._e(),
            !_vm.DDLDraftTable ||
            _vm.defaultGeneratorUiInputData.numberRemainingDDLs > 0 ||
            _vm.defaultGeneratorUiInputData.reGenerate
              ? _c(VProgressCircular, {
                  staticClass: "ml-2",
                  attrs: { color: "primary", indeterminate: "", size: "24" },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.DDLDraftTable && Object.keys(_vm.DDLDraftTable).length > 0
        ? _c(
            VCardText,
            [
              _c(
                VTabs,
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                _vm._l(_vm.DDLDraftTable, function (table, boundedContext) {
                  return _c(VTab, { key: boundedContext }, [
                    _vm._v("\n        " + _vm._s(boundedContext)),
                    _c("br"),
                  ])
                }),
                1
              ),
              _c(
                VTabsItems,
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                _vm._l(_vm.DDLDraftTable, function (table, boundedContext) {
                  return _c(
                    VTabItem,
                    { key: boundedContext },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center mb-2" },
                        [
                          _c("h3", [
                            _vm._v(
                              "Bounded Context: " + _vm._s(boundedContext)
                            ),
                          ]),
                          _vm.defaultGeneratorUiInputData
                            .numberRemainingDDLs === 0 &&
                          !_vm.defaultGeneratorUiInputData.reGenerate
                            ? _c(
                                VBtn,
                                {
                                  staticClass: "gs-es-auto-modling-btn",
                                  staticStyle: {
                                    "margin-left": "10px",
                                    "z-index": "2",
                                  },
                                  attrs: { icon: "", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.reGenerate(
                                        table,
                                        boundedContext
                                      )
                                    },
                                  },
                                },
                                [_c(VIcon, [_vm._v("mdi-refresh")])],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._l(
                        table.recommendations,
                        function (recommendation, index) {
                          return _c(
                            VCard,
                            { key: index, staticClass: "mb-4" },
                            [
                              _c(
                                VCardTitle,
                                { staticClass: "d-flex justify-space-between" },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Option " + _vm._s(recommendation.option)
                                    ),
                                  ]),
                                  _c(VCheckbox, {
                                    attrs: {
                                      "input-value": _vm.isSelected(
                                        boundedContext,
                                        recommendation
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.selectOptionItem(
                                          boundedContext,
                                          recommendation,
                                          $event
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(VCardText, [
                                recommendation.aggregates
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.parseAggregates(
                                          recommendation.aggregates
                                        ),
                                        function (aggregate, name) {
                                          return _c("div", { key: name }, [
                                            _c("strong", [
                                              _vm._v(_vm._s(name)),
                                            ]),
                                            _c("div", { staticClass: "ml-3" }, [
                                              aggregate.entities.length > 0
                                                ? _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-medium",
                                                      },
                                                      [_vm._v("Entities:")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          aggregate.entities.join(
                                                            ", "
                                                          )
                                                        )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              aggregate.valueObjects.length > 0
                                                ? _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-weight-medium",
                                                      },
                                                      [_vm._v("ValueObjects:")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          aggregate.valueObjects.join(
                                                            ", "
                                                          )
                                                        )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]),
                                            _c("br"),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _c("h4", [_vm._v("Pros:")]),
                                _c("p", [_vm._v(_vm._s(recommendation.pros))]),
                                _c("h4", [_vm._v("Cons:")]),
                                _c("p", [_vm._v(_vm._s(recommendation.cons))]),
                              ]),
                            ],
                            1
                          )
                        }
                      ),
                      _c("h4", { staticClass: "mt-4" }, [
                        _vm._v("Conclusions:"),
                      ]),
                      _c("p", [_vm._v(_vm._s(table.conclusions))]),
                    ],
                    2
                  )
                }),
                1
              ),
              _vm.defaultGeneratorUiInputData.numberRemainingDDLs === 0
                ? _c(
                    VBtn,
                    {
                      attrs: {
                        disabled:
                          !_vm.isGeneratorButtonEnabled ||
                          !_vm.selectedOptionItem ||
                          Object.keys(_vm.selectedOptionItem).length !==
                            Object.keys(_vm.DDLDraftTable).length,
                        block: "",
                      },
                      on: { click: _vm.generateFromDraft },
                    },
                    [_vm._v("Generate From Draft")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
</template>

<script>
    import CodeGenerator from "../modeling/CodeGenerator";

    export default {
        name: 'event-storming-code-generator',
        mixins:[CodeGenerator],
        data() {
            return {
            }
        }
    }
</script>
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-panel",
    {
      attrs: {
        image: _vm.image,
        "is-read-only": _vm.isReadOnly,
        "width-style": _vm.widthStyle,
        "related-url": _vm.relatedUrl,
        "validation-lists": _vm.validationLists,
        "translate-obj": _vm.translateObj,
        "element-author-display": _vm.elementAuthorDisplay,
      },
      on: { close: _vm.closePanel, changeTranslate: _vm.changeTranslate },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("template", { slot: "t-title-name" }, [_vm._v("ReadModel")]),
      _c("template", { slot: "t-description-text" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("panelInfo.ViewDefinitionPanel")) +
            "\n    "
        ),
      ]),
      _c("template", { slot: "t-generation-text" }, [
        _vm._v(
          "\n        Read Models become the implementation for data projection.\n    "
        ),
      ]),
      _c("template", { slot: "t-edit-user" }, [
        _vm.newEditUserImg.length > 0 && _vm.isReadOnly
          ? _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  VChip,
                  {
                    staticStyle: { "font-weight": "bold" },
                    attrs: {
                      small: "",
                      color: "orange",
                      "text-color": "white",
                    },
                    on: {
                      "~click": function ($event) {
                        return _vm.forceEditPanel()
                      },
                    },
                  },
                  [
                    _c(
                      VAvatar,
                      { attrs: { left: "" } },
                      [_c(VIcon, [_vm._v("mdi-lead-pencil")])],
                      1
                    ),
                    _c(VRow, [
                      _c("div", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.newEditUserImg[0].name) +
                            " is now editing..."
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "margin-right": "10px",
                          },
                        },
                        [_vm._v(" ( Click to force editing ) ")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("template", { slot: "generateWithAi" }, [
        _c("div", [
          _c("span", [
            _c(
              "div",
              [
                _vm.value.description && _vm.generateDone
                  ? _c(
                      VBtn,
                      {
                        staticClass: "auto-modeling-btn",
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.generate()
                          },
                        },
                      },
                      [
                        _c(VIcon, [_vm._v("mdi-auto-fix")]),
                        _vm._v("(RE)Generate Inside"),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.value.description && !_vm.generateDone
                  ? _c(
                      VBtn,
                      {
                        staticClass: "auto-modeling-btn",
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.stop()
                          },
                        },
                      },
                      [
                        _c(VIcon, [_vm._v("mdi-auto-fix")]),
                        _vm._v("Stop Generation"),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "template",
        { slot: "md-title-side" },
        [
          _c(
            VBtn,
            {
              staticStyle: { "margin-left": "10px", "margin-top": "-12px" },
              attrs: {
                text: "",
                color: "primary",
                disabled:
                  _vm.isReadOnly ||
                  !_vm.exampleAvailable ||
                  _vm.value.dataProjection != "query-for-aggregate",
              },
              on: {
                click: function ($event) {
                  return _vm.openExampleDialog()
                },
              },
            },
            [_vm._v("Examples")]
          ),
          !_vm.exampleAvailable
            ? _c(
                VTooltip,
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              VBtn,
                              _vm._g(
                                _vm._b(
                                  {
                                    staticStyle: {
                                      "margin-left": "-8px",
                                      "margin-top": "-15px",
                                      width: "10px",
                                      height: "10px",
                                    },
                                    attrs: { icon: "" },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c(
                                  VIcon,
                                  { attrs: { color: "grey lighten-1" } },
                                  [_vm._v("mdi-help-circle")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3283573148
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n                The following steps are required to use the 'EXAMPLES'."
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                1. There must be a ReadModel Sticker in another bound Context that forms a sync call relationship with the command. "
                    ),
                    _c("br"),
                    _vm._v("\n                e.g."),
                    _c("br"),
                    _c("img", {
                      attrs: {
                        width: "794",
                        alt: "image",
                        src: "https://github.com/user-attachments/assets/b8a91e73-c806-4ad2-b668-1b1e04e1c3e9",
                      },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "template",
        { slot: "element" },
        [
          _vm.openExample
            ? _c("RuleExampleDialog", {
                on: {
                  closeExampleDialog: function ($event) {
                    return _vm.closeExampleDialog()
                  },
                },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _vm._e(),
          _c(
            VCard,
            { attrs: { flat: "" } },
            [
              _c(
                VCardText,
                [
                  _c(
                    VRadioGroup,
                    {
                      attrs: { disabled: _vm.isReadOnly, row: "" },
                      model: {
                        value: _vm.value.dataProjection,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "dataProjection", $$v)
                        },
                        expression: "value.dataProjection",
                      },
                    },
                    [
                      _c(VRadio, {
                        attrs: {
                          disabled: _vm.isOnlyCQRS,
                          label: "CQRS",
                          value: "cqrs",
                        },
                      }),
                      _c(VRadio, {
                        attrs: {
                          label: "Query For Aggregate",
                          value: "query-for-aggregate",
                        },
                      }),
                      _c(VRadio, {
                        attrs: {
                          disabled: "",
                          label: "GraphQL",
                          value: "graphql",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.value.dataProjection == "query-for-aggregate"
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "15px" } },
                            [
                              _c("span", { staticClass: "panel-title" }, [
                                _vm._v("Associated Aggregate"),
                              ]),
                              _c(VTextField, {
                                staticStyle: { "margin-top": "-15px" },
                                attrs: {
                                  label:
                                    "Position the sticker adjacent to the Aggregate sticker",
                                  "single-line": "",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.relatedAggregateName,
                                  callback: function ($$v) {
                                    _vm.relatedAggregateName = $$v
                                  },
                                  expression: "relatedAggregateName",
                                },
                              }),
                              _c(
                                VRadioGroup,
                                {
                                  attrs: { disabled: _vm.isReadOnly, row: "" },
                                  model: {
                                    value: _vm.value.queryOption.useDefaultUri,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.value.queryOption,
                                        "useDefaultUri",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "value.queryOption.useDefaultUri",
                                  },
                                },
                                [
                                  _c(VRadio, {
                                    attrs: {
                                      label: "Default GET URI",
                                      value: true,
                                    },
                                  }),
                                  _c(VRadio, {
                                    attrs: {
                                      label: "Extended GET URI",
                                      value: false,
                                    },
                                  }),
                                ],
                                1
                              ),
                              !_vm.value.queryOption.useDefaultUri
                                ? _c(
                                    VRow,
                                    {
                                      staticStyle: { "align-items": "center" },
                                    },
                                    [
                                      _c(VTextField, {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          disabled: _vm.isReadOnly,
                                          label: "Get Path",
                                        },
                                        model: {
                                          value: _vm.value.queryOption.apiPath,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.value.queryOption,
                                              "apiPath",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "value.queryOption.apiPath",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                VRadioGroup,
                                {
                                  attrs: { disabled: _vm.isReadOnly, row: "" },
                                  model: {
                                    value: _vm.value.queryOption.multipleResult,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.value.queryOption,
                                        "multipleResult",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "value.queryOption.multipleResult",
                                  },
                                },
                                [
                                  _c(VRadio, {
                                    attrs: {
                                      label: "Single Result",
                                      value: false,
                                    },
                                  }),
                                  _c(VRadio, {
                                    attrs: {
                                      label: "Multiple Result",
                                      value: true,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            VCard,
                            { attrs: { flat: "" } },
                            [
                              _c(
                                VCardText,
                                [
                                  _c("event-storming-attribute", {
                                    attrs: {
                                      label: "Query Parameters",
                                      isReadOnly: _vm.isReadOnly,
                                      type: _vm.value._type,
                                      dataProjection: _vm.value.dataProjection,
                                      elementId: _vm.value.elementView.id,
                                      entities: _vm.relatedAggregate
                                        ? _vm.relatedAggregate.aggregateRoot
                                            .entities
                                        : null,
                                      fields: _vm.relatedAggregate
                                        ? _vm.relatedAggregate.aggregateRoot
                                            .fieldDescriptors
                                        : null,
                                    },
                                    on: {
                                      "sync-attribute": _vm.syncFromAggregate,
                                    },
                                    model: {
                                      value: _vm.value.queryParameters,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.value,
                                          "queryParameters",
                                          $$v
                                        )
                                      },
                                      expression: "value.queryParameters",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.value.dataProjection == "cqrs"
                    ? _c(
                        "div",
                        [
                          _c(
                            VCard,
                            { attrs: { flat: "" } },
                            [
                              _c(
                                VCardText,
                                { staticClass: "pa-0" },
                                [
                                  _c("event-storming-attribute", {
                                    attrs: {
                                      label: "Read Model Attributes",
                                      isReadOnly: _vm.isReadOnly,
                                      type: _vm.value._type,
                                      elementId: _vm.value.elementView.id,
                                    },
                                    model: {
                                      value: _vm.value.fieldDescriptors,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.value,
                                          "fieldDescriptors",
                                          $$v
                                        )
                                      },
                                      expression: "value.fieldDescriptors",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._l(_vm.value.createRules, function (item, key) {
                            return _c(
                              VCol,
                              [
                                _c("ViewCreate", {
                                  attrs: {
                                    createItem: item,
                                    index: key,
                                    isRead: _vm.isReadOnly,
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          _c(
                            VRow,
                            { attrs: { align: "start", justify: "end" } },
                            [
                              _c(
                                VTooltip,
                                {
                                  attrs: { left: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              VBtn,
                                              _vm._g(
                                                {
                                                  staticClass: "cqrs-add-btn",
                                                  attrs: {
                                                    outlined: "",
                                                    disabled: _vm.isReadOnly,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.viewMainRowAdd(
                                                        "create"
                                                      )
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c(VIcon, [
                                                  _vm._v(" mdi-plus"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2693771816
                                  ),
                                },
                                [_c("span", [_vm._v('Add "CREATE" Card')])]
                              ),
                            ],
                            1
                          ),
                          _vm._l(_vm.value.updateRules, function (item, key) {
                            return _c(
                              VCol,
                              [
                                _c("ViewUpdate", {
                                  attrs: {
                                    updateItem: item,
                                    index: key,
                                    isRead: _vm.isReadOnly,
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          _c(
                            VRow,
                            { attrs: { align: "center", justify: "end" } },
                            [
                              _c(
                                VTooltip,
                                {
                                  attrs: { left: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              VBtn,
                                              _vm._g(
                                                {
                                                  staticClass: "cqrs-add-btn",
                                                  attrs: {
                                                    outlined: "",
                                                    disabled: _vm.isReadOnly,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.viewMainRowAdd(
                                                        "update"
                                                      )
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c(VIcon, [
                                                  _vm._v(" mdi-plus"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    2735659293
                                  ),
                                },
                                [_c("span", [_vm._v('Add "UPDATE" Card')])]
                              ),
                            ],
                            1
                          ),
                          _vm._l(_vm.value.deleteRules, function (item, key) {
                            return _c(
                              VCol,
                              [
                                _c("ViewDelete", {
                                  attrs: {
                                    deleteItem: item,
                                    index: key,
                                    isRead: _vm.isReadOnly,
                                  },
                                  model: {
                                    value: _vm.value,
                                    callback: function ($$v) {
                                      _vm.value = $$v
                                    },
                                    expression: "value",
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          _c(
                            VRow,
                            { attrs: { align: "start", justify: "end" } },
                            [
                              _c(
                                VTooltip,
                                {
                                  attrs: { left: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              VBtn,
                                              _vm._g(
                                                {
                                                  staticClass: "cqrs-add-btn",
                                                  attrs: {
                                                    outlined: "",
                                                    disabled: _vm.isReadOnly,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.viewMainRowAdd(
                                                        "delete"
                                                      )
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c(VIcon, [
                                                  _vm._v("mdi-plus"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1840478453
                                  ),
                                },
                                [_c("span", [_vm._v('Add "DELETE" Card')])]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-panel",
    {
      attrs: {
        image: _vm.image,
        "is-read-only": _vm.isReadOnly,
        "width-style": _vm.widthStyle,
        "related-url": _vm.relatedUrl,
        "validation-lists": _vm.validationLists,
        "translate-obj": _vm.translateObj,
        "element-author-display": _vm.elementAuthorDisplay,
      },
      on: { close: _vm.closePanel, changeTranslate: _vm.changeTranslate },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("template", { slot: "t-description-text" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("panelInfo.DomainEventDefinitionPanel")) +
            "\n    "
        ),
      ]),
      _c("template", { slot: "t-generation-text" }, [
        _vm._v(
          "\n        Event becomes a domain event class containing the properties of the event that must be dispatched, and finally serialized into JSON format so that it can be posted to an event store such as Kafka.\n    "
        ),
      ]),
      _c("template", { slot: "t-edit-user" }, [
        _vm.newEditUserImg.length > 0 &&
        _vm.isReadOnly &&
        !_vm.value.mirrorElement
          ? _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  VChip,
                  {
                    staticStyle: { "font-weight": "bold" },
                    attrs: {
                      small: "",
                      color: "orange",
                      "text-color": "white",
                    },
                    on: {
                      "~click": function ($event) {
                        return _vm.forceEditPanel()
                      },
                    },
                  },
                  [
                    _c(
                      VAvatar,
                      { attrs: { left: "" } },
                      [_c(VIcon, [_vm._v("mdi-lead-pencil")])],
                      1
                    ),
                    _c(VRow, [
                      _c("div", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.newEditUserImg[0].name) +
                            " is now editing..."
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "margin-right": "10px",
                          },
                        },
                        [_vm._v(" ( Click to force editing ) ")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("template", { slot: "element" }, [
        _c(
          "div",
          [
            _c(
              VCard,
              { attrs: { flat: "" } },
              [
                _c(
                  VCardText,
                  [
                    _c("event-storming-attribute", {
                      attrs: {
                        isReadOnly: _vm.isReadOnly,
                        type: _vm.value._type,
                        elementId: _vm.value.elementView.id,
                      },
                      on: { "sync-attribute": _vm.syncFromAggregate },
                      model: {
                        value: _vm.value.fieldDescriptors,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "fieldDescriptors", $$v)
                        },
                        expression: "value.fieldDescriptors",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              VCard,
              { attrs: { flat: "" } },
              [
                _c(
                  "div",
                  { staticClass: "pa-4 pb-0" },
                  [
                    _c(
                      VCardText,
                      { staticClass: "pa-0 pb-2 panel-title" },
                      [_vm._v("Trigger By LifeCycle")]
                    ),
                  ],
                  1
                ),
                _c(
                  VCardText,
                  { staticClass: "pt-0" },
                  [
                    _c(
                      VRadioGroup,
                      {
                        staticClass: "mt-1",
                        staticStyle: { width: "290px" },
                        attrs: { disabled: _vm.isReadOnly },
                        model: {
                          value: _vm.value.trigger,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "trigger", $$v)
                          },
                          expression: "value.trigger",
                        },
                      },
                      [
                        _c(
                          VRow,
                          { staticClass: "pa-0 ma-0", attrs: { dense: "" } },
                          [
                            _c(
                              VCol,
                              { staticClass: "pa-0", attrs: { dense: "" } },
                              [
                                _vm.lifeCycleCommand.includes("POST")
                                  ? _c(VRadio, {
                                      staticStyle: { width: "110px" },
                                      attrs: {
                                        label: "Pre Persist",
                                        value: "@PrePersist",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.lifeCycleCommand.includes("PATCH")
                                  ? _c(VRadio, {
                                      attrs: {
                                        label: "Pre Update",
                                        value: "@PreUpdate",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.lifeCycleCommand.includes("PUT")
                                  ? _c(VRadio, {
                                      attrs: {
                                        label: "Pre Update",
                                        value: "@PreUpdate",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.lifeCycleCommand.includes("DELETE")
                                  ? _c(VRadio, {
                                      attrs: {
                                        label: "Pre Remove",
                                        value: "@PreRemove",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              VCol,
                              { staticClass: "pa-0", attrs: { dense: "" } },
                              [
                                _vm.lifeCycleCommand.includes("POST")
                                  ? _c(VRadio, {
                                      attrs: {
                                        label: "Post Persist",
                                        value: "@PostPersist",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.lifeCycleCommand.includes("PATCH")
                                  ? _c(VRadio, {
                                      attrs: {
                                        label: "Post Update",
                                        value: "@PostUpdate",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.lifeCycleCommand.includes("PUT")
                                  ? _c(VRadio, {
                                      attrs: {
                                        label: "Post Update",
                                        value: "@PostUpdate",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.lifeCycleCommand.includes("DELETE")
                                  ? _c(VRadio, {
                                      staticStyle: { width: "130px" },
                                      attrs: {
                                        label: "Post Remove",
                                        value: "@PostRemove",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              VCard,
              [
                _c(VCardText, { staticClass: "panel-title" }, [
                  _vm._v("Trigger By Command"),
                ]),
                _vm.commandLists && _vm.commandLists.length
                  ? _c(
                      VCardText,
                      [
                        _c(
                          VRow,
                          {
                            staticStyle: {
                              "font-size": "16px",
                              padding: "20px 0 20px 0",
                            },
                          },
                          _vm._l(_vm.commandLists, function (command, idx) {
                            return _c(
                              VCol,
                              { key: idx, attrs: { cols: "4" } },
                              [_vm._v("● " + _vm._s(command.name))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              VCard,
              [
                _c(VCardText, { staticClass: "panel-title" }, [
                  _vm._v("Trigger By Policy"),
                ]),
                _vm.policyLists && _vm.policyLists.length
                  ? _c(
                      VCardText,
                      [
                        _c(
                          VRow,
                          {
                            staticStyle: {
                              "font-size": "16px",
                              padding: "20px 0 20px 0",
                            },
                          },
                          _vm._l(_vm.policyLists, function (policy, idx) {
                            return _c(
                              VCol,
                              { key: idx, attrs: { cols: "4" } },
                              [_vm._v("● " + _vm._s(policy.name))]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              VCard,
              [
                _c(VCardText, { staticClass: "panel-title" }, [
                  _vm._v("Associated Aggregate"),
                ]),
                _c(
                  VCardText,
                  [
                    _c(VTextField, {
                      attrs: {
                        label: "Attach Aggregate && check Name",
                        "single-line": "",
                        disabled: "",
                      },
                      model: {
                        value: _vm.relatedAggregateName,
                        callback: function ($$v) {
                          _vm.relatedAggregateName = $$v
                        },
                        expression: "relatedAggregateName",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
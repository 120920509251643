import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: { "hide-overlay": "", "max-width": "400" },
      on: { "click:outside": _vm.closeDialog },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        VCard,
        [
          _c(VCardTitle, [_vm._v("Git Setting for Model")]),
          _c(
            VCardText,
            [
              _c(VTextField, {
                attrs: { label: "Git URL Input" },
                model: {
                  value: _vm.gitURL,
                  callback: function ($$v) {
                    _vm.gitURL = $$v
                  },
                  expression: "gitURL",
                },
              }),
            ],
            1
          ),
          _c(
            VCardActions,
            { staticStyle: { "justify-content": "flex-end" } },
            [
              _c(
                VBtn,
                { attrs: { text: "" }, on: { click: _vm.closeDialog } },
                [_vm._v("Close")]
              ),
              _c(
                VBtn,
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.setDialog },
                },
                [_vm._v("OK")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
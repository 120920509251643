import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VDialog,
        {
          attrs: { persistent: _vm.isGenerating ? true : false },
          model: {
            value: _vm.isOpenRules,
            callback: function ($$v) {
              _vm.isOpenRules = $$v
            },
            expression: "isOpenRules",
          },
        },
        [
          _c(
            VCard,
            { staticStyle: { padding: "15px", display: "inline-table" } },
            [
              _c(
                "table",
                { staticClass: "rules-table", attrs: { cellspacing: "0" } },
                [
                  _c(
                    "tr",
                    [
                      _c(
                        "td",
                        {
                          staticStyle: {
                            "font-size": "20px",
                            "font-weight": "500",
                            "padding-bottom": "12px",
                          },
                          attrs: { colspan: "999" },
                        },
                        [_vm._v(_vm._s(_vm.rule.ruleName))]
                      ),
                      _c(
                        VIcon,
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "10px",
                            top: "10px",
                          },
                          on: {
                            click: function ($event) {
                              _vm.isOpenRules = false
                            },
                          },
                        },
                        [_vm._v("mdi-close")]
                      ),
                    ],
                    1
                  ),
                  _c("tr", [
                    _c(
                      "td",
                      { attrs: { colspan: "999" } },
                      [
                        _c(VTextField, {
                          attrs: { label: "Describe your business logic here" },
                          model: {
                            value: _vm.rule.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.rule, "description", $$v)
                            },
                            expression: "rule.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "tr",
                    {
                      staticClass: "tr-divider",
                      staticStyle: {
                        "text-align": "center",
                        "font-size": "18px",
                        "font-weight": "500",
                      },
                    },
                    [
                      _c("td", { attrs: { colspan: _vm.givenAttLength } }, [
                        _vm._v("Given"),
                      ]),
                      _c("td", { attrs: { colspan: _vm.whenAttLength } }, [
                        _vm._v("When"),
                      ]),
                      _c("td", { attrs: { colspan: "999" } }, [_vm._v("Then")]),
                    ]
                  ),
                  _c(
                    "tr",
                    {
                      staticClass: "tr-divider",
                      staticStyle: {
                        "text-align": "center",
                        "font-weight": "500",
                      },
                    },
                    [
                      _c("td", { attrs: { colspan: _vm.givenAttLength } }, [
                        _vm._v(_vm._s(_vm.ruleExampleTableHeaders.given)),
                      ]),
                      _c("td", { attrs: { colspan: _vm.whenAttLength } }, [
                        _vm._v(_vm._s(_vm.ruleExampleTableHeaders.when)),
                      ]),
                      _vm._l(_vm.ruleExampleTableHeaders.then, function (row) {
                        return _c(
                          "td",
                          { attrs: { colspan: _vm.thenAttLength[row] } },
                          [_vm._v(_vm._s(row))]
                        )
                      }),
                    ],
                    2
                  ),
                  _c(
                    "tr",
                    { staticClass: "tr-divider" },
                    [
                      _vm._l(
                        Object.keys(_vm.rule.values[0]["given"][0].value),
                        function (key) {
                          return _c("td", [_vm._v(_vm._s(key))])
                        }
                      ),
                      _vm._l(
                        Object.keys(_vm.rule.values[0]["when"][0].value),
                        function (key) {
                          return _c("td", [_vm._v(_vm._s(key))])
                        }
                      ),
                      _vm._l(_vm.rule.values[0]["then"], function (attribute) {
                        return _vm._l(
                          Object.keys(attribute.value),
                          function (key) {
                            return _c("td", [_vm._v(_vm._s(key))])
                          }
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._l(_vm.rule.values, function (value, ruleIdx) {
                    return [
                      _c(
                        "tr",
                        {
                          staticClass: "tr-divider tr-input",
                          staticStyle: { "border-bottom": "1px solid #E0E0E0" },
                        },
                        [
                          _vm._l(
                            value["given"][0].value,
                            function (given, key) {
                              return [
                                _vm.checkGivenType(given)
                                  ? [
                                      _c(
                                        "td",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.selectTableData(
                                                ruleIdx,
                                                "given",
                                                key
                                              )
                                            },
                                          },
                                        },
                                        [
                                          "given-" +
                                            _vm.rule["givenItems"][0].name +
                                            "-" +
                                            key ==
                                            _vm.selectedItemPath &&
                                          _vm.selectedItemIndex == ruleIdx
                                            ? _c(
                                                _vm.getComponentType(
                                                  _vm.selectedAttType
                                                ),
                                                {
                                                  tag: "component",
                                                  staticClass:
                                                    "td-component-size",
                                                  attrs: {
                                                    label: _vm.selectedAttType,
                                                    items:
                                                      _vm.selectedEnumItems,
                                                  },
                                                  on: {
                                                    save: function ($event) {
                                                      return _vm.closeExampleEditor()
                                                    },
                                                    selectChip:
                                                      _vm.closeExampleEditor,
                                                  },
                                                  model: {
                                                    value:
                                                      value["given"][0].value[
                                                        key
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        value["given"][0].value,
                                                        key,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "value['given'][0].value[key]",
                                                  },
                                                }
                                              )
                                            : _c(
                                                "div",
                                                [
                                                  _vm.chipLabels[given]
                                                    ? _c(
                                                        VChip,
                                                        {
                                                          staticClass:
                                                            "rule-chip",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.chipLabels[
                                                                given
                                                              ]
                                                            )
                                                          ),
                                                        ]
                                                      )
                                                    : [_vm._v(_vm._s(given))],
                                                ],
                                                2
                                              ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _c(
                                      "table",
                                      {
                                        staticClass: "rules-table",
                                        staticStyle: { width: "100%" },
                                      },
                                      [
                                        _c(
                                          "tr",
                                          _vm._l(
                                            given[0],
                                            function (
                                              givenArrValue,
                                              givenArrKey
                                            ) {
                                              return _c(
                                                "td",
                                                {
                                                  key: givenArrKey,
                                                  staticClass: "given-td-uml",
                                                },
                                                [_vm._v(_vm._s(givenArrKey))]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _vm._l(
                                          given,
                                          function (givenValue, givenIdx) {
                                            return _c(
                                              "tr",
                                              { key: givenIdx },
                                              [
                                                _vm._l(
                                                  givenValue,
                                                  function (
                                                    givenArrValue,
                                                    givenArrKey
                                                  ) {
                                                    return _c(
                                                      "td",
                                                      {
                                                        key: givenArrKey,
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.selectTableData(
                                                              ruleIdx,
                                                              "given",
                                                              givenArrKey,
                                                              null,
                                                              null,
                                                              key,
                                                              givenIdx
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        "given-" +
                                                          _vm.rule[
                                                            "givenItems"
                                                          ][0].name +
                                                          "-" +
                                                          key +
                                                          "-" +
                                                          givenArrKey ==
                                                          _vm.selectedItemPath &&
                                                        _vm.selectedItemIndex ==
                                                          ruleIdx &&
                                                        _vm.selectedGivenIndex ==
                                                          givenIdx
                                                          ? _c(
                                                              _vm.getComponentType(
                                                                _vm.selectedAttType
                                                              ),
                                                              {
                                                                tag: "component",
                                                                staticClass:
                                                                  "td-component-size",
                                                                attrs: {
                                                                  label:
                                                                    _vm.selectedAttType,
                                                                },
                                                                on: {
                                                                  save: function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.closeExampleEditor()
                                                                  },
                                                                  selectChip:
                                                                    _vm.closeExampleEditor,
                                                                },
                                                                model: {
                                                                  value:
                                                                    givenValue[
                                                                      givenArrKey
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        givenValue,
                                                                        givenArrKey,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "givenValue[givenArrKey]",
                                                                },
                                                              }
                                                            )
                                                          : _c(
                                                              "div",
                                                              [
                                                                _vm.chipLabels[
                                                                  givenValue[
                                                                    givenArrKey
                                                                  ]
                                                                ]
                                                                  ? _c(
                                                                      VChip,
                                                                      {
                                                                        staticClass:
                                                                          "rule-chip",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .chipLabels[
                                                                              givenValue[
                                                                                givenArrKey
                                                                              ]
                                                                            ]
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          givenValue[
                                                                            givenArrKey
                                                                          ]
                                                                        )
                                                                      ),
                                                                    ],
                                                              ],
                                                              2
                                                            ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  VIcon,
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeGivenExample(
                                                          key,
                                                          ruleIdx,
                                                          givenIdx
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("mdi-delete")]
                                                ),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        _c(
                                          VIcon,
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.addGivenExample(
                                                  key,
                                                  ruleIdx
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("mdi-plus")]
                                        ),
                                      ],
                                      2
                                    ),
                              ]
                            }
                          ),
                          _vm._l(
                            Object.keys(value["when"][0].value),
                            function (key) {
                              return _c(
                                "td",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectTableData(
                                        ruleIdx,
                                        "when",
                                        key
                                      )
                                    },
                                  },
                                },
                                [
                                  "when-" +
                                    _vm.rule["whenItems"][0].name +
                                    "-" +
                                    key ==
                                    _vm.selectedItemPath &&
                                  _vm.selectedItemIndex == ruleIdx
                                    ? _c(
                                        _vm.getComponentType(
                                          _vm.selectedAttType
                                        ),
                                        {
                                          tag: "component",
                                          staticClass: "td-component-size",
                                          attrs: { label: _vm.selectedAttType },
                                          on: {
                                            save: function ($event) {
                                              return _vm.closeExampleEditor()
                                            },
                                            selectChip: _vm.closeExampleEditor,
                                          },
                                          model: {
                                            value: value["when"][0].value[key],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                value["when"][0].value,
                                                key,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "value['when'][0].value[key]",
                                          },
                                        }
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _vm.chipLabels[
                                            value["when"][0].value[key]
                                          ]
                                            ? _c(
                                                VChip,
                                                { staticClass: "rule-chip" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.chipLabels[
                                                        value["when"][0].value[
                                                          key
                                                        ]
                                                      ]
                                                    )
                                                  ),
                                                ]
                                              )
                                            : [
                                                _vm._v(
                                                  _vm._s(
                                                    value["when"][0].value[key]
                                                  )
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                ],
                                1
                              )
                            }
                          ),
                          _vm._l(value["then"], function (then, thenIdx) {
                            return _vm._l(
                              Object.keys(then.value),
                              function (key) {
                                return _c(
                                  "td",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectTableData(
                                          ruleIdx,
                                          "then",
                                          key,
                                          thenIdx,
                                          then.name
                                        )
                                      },
                                    },
                                  },
                                  [
                                    "then-" +
                                      _vm.rule["thenItems"][thenIdx].name +
                                      "-" +
                                      key ==
                                      _vm.selectedItemPath &&
                                    _vm.selectedItemIndex == ruleIdx
                                      ? _c(
                                          _vm.getComponentType(
                                            _vm.selectedAttType
                                          ),
                                          {
                                            tag: "component",
                                            staticClass: "td-component-size",
                                            attrs: {
                                              label: _vm.selectedAttType,
                                            },
                                            on: {
                                              save: function ($event) {
                                                return _vm.closeExampleEditor()
                                              },
                                              selectChip:
                                                _vm.closeExampleEditor,
                                            },
                                            model: {
                                              value: then.value[key],
                                              callback: function ($$v) {
                                                _vm.$set(then.value, key, $$v)
                                              },
                                              expression: "then.value[key]",
                                            },
                                          }
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _vm.chipLabels[then.value[key]]
                                              ? _c(
                                                  VChip,
                                                  { staticClass: "rule-chip" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.chipLabels[
                                                          then.value[key]
                                                        ]
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : [
                                                  _vm._v(
                                                    _vm._s(then.value[key])
                                                  ),
                                                ],
                                          ],
                                          2
                                        ),
                                  ],
                                  1
                                )
                              }
                            )
                          }),
                          _c(
                            VIcon,
                            {
                              staticStyle: {
                                position: "absolute",
                                right: "10px",
                                "margin-top": "5px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeExample(ruleIdx)
                                },
                              },
                            },
                            [_vm._v("mdi-delete")]
                          ),
                        ],
                        2
                      ),
                    ]
                  }),
                ],
                2
              ),
              _c(
                VLayout,
                { staticStyle: { float: "left", "margin-top": "10px" } },
                [
                  _c(VSpacer),
                  _c(
                    VIcon,
                    {
                      on: {
                        click: function ($event) {
                          return _vm.addExample()
                        },
                      },
                    },
                    [_vm._v("mdi-plus")]
                  ),
                  _c(
                    VIcon,
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetExampleDialog()
                        },
                      },
                    },
                    [_vm._v("mdi-refresh")]
                  ),
                  !_vm.isGenerating
                    ? _c(
                        VBtn,
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.startExampleGenerate()
                            },
                          },
                        },
                        [_vm._v("Generate Examples")]
                      )
                    : _vm._e(),
                  _vm.isGenerating
                    ? _c(
                        VBtn,
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.stopExampleGenerate()
                            },
                          },
                        },
                        [
                          _c(VProgressCircular, {
                            staticStyle: { "margin-right": "10px" },
                            attrs: {
                              size: "15",
                              width: 3,
                              indeterminate: "",
                              color: "primary",
                            },
                          }),
                          _vm._v(
                            "\n                    Stop generating\n                "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("separate-panel-components", {
        attrs: {
          min: _vm.mainSeparatePanel.min,
          max: _vm.mainSeparatePanel.max,
          triggerLength: 5,
          paneLengthPercent: _vm.mainSeparatePanel.current,
          inBoundSeparatePanel: false,
        },
        on: {
          "update:paneLengthPercent": function ($event) {
            return _vm.$set(_vm.mainSeparatePanel, "current", $event)
          },
          "update:pane-length-percent": function ($event) {
            return _vm.$set(_vm.mainSeparatePanel, "current", $event)
          },
          close: function ($event) {
            return _vm.closeSeparatePanel()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "one",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", height: "100%" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "canvas-panel",
                          staticStyle: { left: "0" },
                        },
                        [
                          _vm.showOverlay
                            ? _c(
                                VOverlay,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { align: "center" } },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.showOverlay)),
                                      ]),
                                      _c(
                                        VProgressCircular,
                                        {
                                          attrs: {
                                            indeterminate: "",
                                            size: "64",
                                          },
                                        },
                                        [
                                          _c(VBtn, {
                                            attrs: { text: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.closeOverlay()
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            VLayout,
                            [
                              _c(
                                "modal",
                                {
                                  attrs: {
                                    name: "uml-modal",
                                    height: "80%",
                                    width: "80%",
                                  },
                                },
                                [_c("class-modeler")],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            VSnackbar,
                            {
                              attrs: {
                                color: "primary",
                                "multi-line": "",
                                timeout: 5000,
                              },
                              model: {
                                value: _vm.deleteSnackbar,
                                callback: function ($$v) {
                                  _vm.deleteSnackbar = $$v
                                },
                                expression: "deleteSnackbar",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        해당 BoundedContext ( " +
                                  _vm._s(_vm.deleteBounded) +
                                  " )데이터가\n                        삭제되었습니다.\n                        "
                              ),
                              _c(
                                VBtn,
                                {
                                  attrs: { dark: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.deleteSnackbar = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Close\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            VSnackbar,
                            {
                              attrs: {
                                timeout: _vm.ideSnackBar.timeout,
                                "auto-height": "",
                                color: _vm.ideSnackBar.Color,
                                "multi-line": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "action",
                                  fn: function ({ attrs }) {
                                    return [
                                      _vm.ideSnackBar.wrongGitInfo
                                        ? _c(
                                            VBtn,
                                            _vm._b(
                                              {
                                                attrs: {
                                                  color: "white",
                                                  outlined: "",
                                                  small: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.functionSelect(
                                                      "opentheiaIDE"
                                                    ),
                                                      (_vm.ideSnackBar.show = false)
                                                  },
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            [
                                              _vm._v(
                                                "\n                                open with theia IDE\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        VBtn,
                                        _vm._b(
                                          {
                                            attrs: {
                                              color: "white",
                                              text: "",
                                              small: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.ideSnackBar.show = false
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        [
                                          _vm._v(
                                            "\n                                Close\n                            "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.ideSnackBar.show,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ideSnackBar, "show", $$v)
                                },
                                expression: "ideSnackBar.show",
                              },
                            },
                            [
                              _c(
                                VLayout,
                                { attrs: { "align-center": "", "pr-4": "" } },
                                [
                                  _vm.ideSnackBar.icon
                                    ? _c(
                                        VIcon,
                                        {
                                          staticClass: "pr-3",
                                          attrs: { dark: "", large: "" },
                                        },
                                        [_vm._v(_vm._s(_vm.ideSnackBar.icon))]
                                      )
                                    : _vm._e(),
                                  _c(VLayout, { attrs: { column: "" } }, [
                                    _vm.ideSnackBar.title
                                      ? _c("div", [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(_vm.ideSnackBar.title)
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _c("div", [
                                      _vm._v(_vm._s(_vm.ideSnackBar.Text)),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            VSnackbar,
                            {
                              attrs: {
                                outlined: "",
                                color: _vm.snackbar.color,
                                "multi-line":
                                  _vm.snackbar.mode === "multi-line",
                                timeout: _vm.snackbar.timeout,
                                vertical: _vm.snackbar.mode === "vertical",
                                top: _vm.snackbar.top,
                                bottom: _vm.snackbar.bottom,
                                centered: _vm.snackbar.centered,
                              },
                              model: {
                                value: _vm.snackbar.show,
                                callback: function ($$v) {
                                  _vm.$set(_vm.snackbar, "show", $$v)
                                },
                                expression: "snackbar.show",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.snackbar.text) +
                                  "\n                        "
                              ),
                              _vm.snackbar.closeBtn
                                ? _c(
                                    VBtn,
                                    {
                                      staticStyle: { "margin-left": "10px" },
                                      attrs: { dark: "", small: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.snackbar.show = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            close\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            VAlert,
                            {
                              staticStyle: {
                                position: "absolute",
                                "z-index": "999",
                                left: "50%",
                                transform: "translate(-50%, 0%)",
                              },
                              attrs: {
                                dense: "",
                                elevation: "6",
                                "colored-border": "",
                                value: _vm.alertInfo.show,
                                border: _vm.alertInfo.border,
                                type: _vm.alertInfo.type,
                                "max-width": _vm.alertInfo.maxWidth,
                                color: _vm.alertInfo.color,
                                width: "100%",
                              },
                            },
                            [
                              _c("div", { staticStyle: { color: "black" } }, [
                                _vm._v(_vm._s(_vm.alertInfo.text)),
                              ]),
                              _vm.alertInfo.link
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        target: "_blank",
                                        href: _vm.alertInfo.link,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Github Persenal Access Token 발행 방법"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticStyle: { "text-align": "right" } },
                                [
                                  _vm.alertInfo.submit
                                    ? _c(
                                        VBtn,
                                        {
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                          attrs: {
                                            small: "",
                                            color: "green",
                                            dark: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.alertSubmit(
                                                _vm.alertInfo.fnNum
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(_vm.alertInfo.submit) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { small: "", text: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.alertClose(
                                            _vm.alertInfo.fnNum
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("word.close")))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            VLayout,
                            { attrs: { right: "" } },
                            [
                              _c(
                                "div",
                                { key: _vm.canvasRenderKey },
                                [
                                  _vm.value
                                    ? _c(
                                        "opengraph",
                                        {
                                          ref: "opengraph",
                                          attrs: {
                                            width: 100000,
                                            height: 100000,
                                            sliderLocationScale:
                                              _vm.sliderLocationScale,
                                            "focus-canvas-on-select": "",
                                            wheelScalable: "",
                                            labelEditable: true,
                                            dragPageMovable:
                                              _vm.dragPageMovable,
                                            enableContextmenu: false,
                                            automaticGuidance:
                                              _vm.automaticGuidance,
                                            enableRootContextmenu: false,
                                            enableHotkeyCtrlC: false,
                                            enableHotkeyCtrlV: false,
                                            enableHotkeyDelete: false,
                                            enableHotkeyCtrlZ: false,
                                            enableHotkeyCtrlD: false,
                                            enableHotkeyCtrlG: false,
                                            slider: true,
                                            movable: !_vm.isReadOnlyModel,
                                            resizable: !_vm.isReadOnlyModel,
                                            selectable: true,
                                            connectable: !_vm.isReadOnlyModel,
                                            autoSliderUpdate: true,
                                            imageBase: _vm.imageBase,
                                          },
                                          on: {
                                            "update:sliderLocationScale":
                                              function ($event) {
                                                _vm.sliderLocationScale = $event
                                              },
                                            connectShape: _vm.onConnectShape,
                                            canvasReady: _vm.bindEvents,
                                          },
                                        },
                                        [
                                          _vm._l(
                                            Object.keys(_vm.value.elements),
                                            function (elementId) {
                                              return _vm.value.elements &&
                                                typeof _vm.value.elements ==
                                                  "object"
                                                ? _c(
                                                    "div",
                                                    [
                                                      elementId &&
                                                      _vm.value.elements[
                                                        elementId
                                                      ] &&
                                                      _vm.validateElementFormat(
                                                        _vm.value.elements[
                                                          elementId
                                                        ]
                                                      )
                                                        ? _c(
                                                            _vm.getComponentByClassName(
                                                              _vm.value
                                                                .elements[
                                                                elementId
                                                              ]._type
                                                            ),
                                                            {
                                                              ref: elementId,
                                                              refInFor: true,
                                                              tag: "component",
                                                              attrs: {
                                                                value:
                                                                  _vm.value
                                                                    .elements[
                                                                    elementId
                                                                  ],
                                                              },
                                                              on: {
                                                                "update:value":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.value
                                                                        .elements,
                                                                      elementId,
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                          _vm._l(
                                            Object.keys(_vm.value.relations),
                                            function (relationId) {
                                              return _vm.value.relations &&
                                                typeof _vm.value.relations ==
                                                  "object"
                                                ? _c(
                                                    "div",
                                                    [
                                                      relationId &&
                                                      _vm.value.relations[
                                                        relationId
                                                      ] &&
                                                      _vm.validateRelationFormat(
                                                        _vm.value.relations[
                                                          relationId
                                                        ]
                                                      )
                                                        ? _c(
                                                            _vm.getComponentByClassName(
                                                              _vm.value
                                                                .relations[
                                                                relationId
                                                              ]._type
                                                            ),
                                                            {
                                                              ref: relationId,
                                                              refInFor: true,
                                                              tag: "component",
                                                              attrs: {
                                                                value:
                                                                  _vm.value
                                                                    .relations[
                                                                    relationId
                                                                  ],
                                                              },
                                                              on: {
                                                                "update:value":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.value
                                                                        .relations,
                                                                      relationId,
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                          _vm.filteredPBCValue.elements &&
                                          typeof _vm.filteredPBCValue
                                            .elements == "object"
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  Object.keys(
                                                    _vm.filteredPBCValue
                                                      .elements
                                                  ),
                                                  function (elementId) {
                                                    return _c(
                                                      "div",
                                                      { key: elementId },
                                                      [
                                                        elementId &&
                                                        _vm.filteredPBCValue
                                                          .elements[elementId]
                                                          ? _c(
                                                              _vm.getComponentByClassName(
                                                                _vm
                                                                  .filteredPBCValue
                                                                  .elements[
                                                                  elementId
                                                                ]._type
                                                              ),
                                                              {
                                                                ref: elementId,
                                                                refInFor: true,
                                                                tag: "component",
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .filteredPBCValue
                                                                      .elements[
                                                                      elementId
                                                                    ],
                                                                  isPBCModel: true,
                                                                },
                                                                on: {
                                                                  "update:value":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        _vm
                                                                          .filteredPBCValue
                                                                          .elements,
                                                                        elementId,
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                          _vm.filteredPBCValue.relations &&
                                          typeof _vm.filteredPBCValue
                                            .relations == "object"
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  Object.keys(
                                                    _vm.filteredPBCValue
                                                      .relations
                                                  ),
                                                  function (relationId) {
                                                    return _c(
                                                      "div",
                                                      { key: relationId },
                                                      [
                                                        relationId &&
                                                        _vm.filteredPBCValue
                                                          .relations[relationId]
                                                          ? _c(
                                                              _vm.getComponentByClassName(
                                                                _vm
                                                                  .filteredPBCValue
                                                                  .relations[
                                                                  relationId
                                                                ]._type
                                                              ),
                                                              {
                                                                ref: relationId,
                                                                refInFor: true,
                                                                tag: "component",
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .filteredPBCValue
                                                                      .relations[
                                                                      relationId
                                                                    ],
                                                                  isPBCModel: true,
                                                                },
                                                                on: {
                                                                  "update:value":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        _vm
                                                                          .filteredPBCValue
                                                                          .relations,
                                                                        relationId,
                                                                        $event
                                                                      )
                                                                    },
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              !_vm.isReadOnlyModel && !_vm.isHexagonal
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticClass: "gs-modeling-undo-redo",
                                        },
                                        [
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          VBtn,
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "gs-model-z-index-2 gs-undo-opacity-hover",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isUndoDisabled,
                                                                text: "",
                                                                small: "",
                                                                right: "",
                                                              },
                                                              nativeOn: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.undo()
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              VIcon,
                                                              {
                                                                attrs: {
                                                                  medium: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-undo"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                695928026
                                              ),
                                            },
                                            [_c("span", [_vm._v("Undo")])]
                                          ),
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          VBtn,
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "gs-model-z-index-2 gs-redo-opacity-hover",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isRedoDisabled,
                                                                text: "",
                                                                small: "",
                                                                right: "",
                                                              },
                                                              nativeOn: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.redo()
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              VIcon,
                                                              {
                                                                attrs: {
                                                                  medium: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-redo"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                237927002
                                              ),
                                            },
                                            [_c("span", [_vm._v("Redo")])]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticStyle: { margin: "0 auto" } },
                                [
                                  _vm._t("top", function () {
                                    return [
                                      _c(
                                        VRow,
                                        [
                                          _c(
                                            VRow,
                                            {
                                              staticClass:
                                                "gs-model-z-index-1 es-is-mobile-project-name",
                                              staticStyle: {
                                                margin: "5px 5px 0px 0px",
                                                height: "64px",
                                                "max-width": "200px",
                                                "background-color":
                                                  "transparent",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "eventstorming-mobile-home-button",
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    { attrs: { to: "/" } },
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          staticStyle: {
                                                            height: "24px",
                                                            "margin-top":
                                                              "38px",
                                                            "margin-right":
                                                              "5px",
                                                          },
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    mdi-home\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VIcon,
                                                {
                                                  staticStyle: {
                                                    height: "24px",
                                                    "margin-top": "38px",
                                                  },
                                                  attrs: {
                                                    disabled: _vm.disableBtn,
                                                    color: "#8d8d8d",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openExportDialog()
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-cog\n                                        "
                                                  ),
                                                ]
                                              ),
                                              _vm._t(
                                                "projectName",
                                                function () {
                                                  return [
                                                    _c(VTextField, {
                                                      staticClass:
                                                        "es-modeling-project-name",
                                                      attrs: {
                                                        disabled:
                                                          _vm.isReadOnlyModel ||
                                                          (_vm.fullPath &&
                                                            _vm.fullPath.includes(
                                                              "replay"
                                                            )),
                                                        color:
                                                          _vm.projectNameColor,
                                                        "error-messages":
                                                          _vm.projectNameHint,
                                                        label: "Project Name",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.unselectedAll.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: _vm.projectName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.projectName = $$v
                                                        },
                                                        expression:
                                                          "projectName",
                                                      },
                                                    }),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "es-is-not-mobile",
                                              staticStyle: {
                                                margin: "40px 0px 0px 15px",
                                                "z-index": "1",
                                              },
                                            },
                                            [
                                              _c(
                                                VRow,
                                                {
                                                  staticStyle: {
                                                    "margin-right": "15px",
                                                  },
                                                  attrs: {
                                                    justify: "end",
                                                    align: "start",
                                                  },
                                                },
                                                [
                                                  _vm._t(
                                                    "menu-buttons",
                                                    function () {
                                                      return [
                                                        _c(VMenu, {
                                                          staticClass: "pa-2",
                                                          attrs: {
                                                            "open-on-hover": "",
                                                            "offset-y": "",
                                                            left: "",
                                                          },
                                                          scopedSlots: _vm._u([
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        VBtn,
                                                                        {
                                                                          staticClass:
                                                                            "gs-model-z-index-1 es-hide-k8s-btn",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "5px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              text: "",
                                                                              disabled:
                                                                                _vm.disableBtn,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.openEmbeddedCanvas(
                                                                                  "Kubernetes"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VIcon,
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-kubernetes"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "es-hide-k8s",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "DEPLOY"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ]),
                                                        }),
                                                        _vm.isServerModel &&
                                                        !_vm.isClazzModeling
                                                          ? _c(
                                                              VMenu,
                                                              {
                                                                staticClass:
                                                                  "pa-2",
                                                                attrs: {
                                                                  "open-on-hover":
                                                                    "",
                                                                  "offset-y":
                                                                    "",
                                                                  left: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _vm.isHexagonal
                                                                                  ? _c(
                                                                                      VBtn,
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "gs-model-z-index-1",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-right":
                                                                                                "5px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              text: "",
                                                                                              disabled:
                                                                                                _vm.disableBtn,
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.generateModel()
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          VIcon,
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-hexagon-outline"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                    Hexagonal\n                                                                "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _c(
                                                                                      VBtn,
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "gs-model-z-index-1 es-hide-view-btn",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-right":
                                                                                                "5px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              text: "",
                                                                                              disabled:
                                                                                                _vm.disableBtn,
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.generateHexagonal()
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          VIcon,
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-view-dashboard"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "es-hide-view",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "VIEW"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    157078953
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  VList,
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        overflow:
                                                                          "hidden",
                                                                      },
                                                                  },
                                                                  _vm._l(
                                                                    _vm.conversionItems,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        VListItem,
                                                                        {
                                                                          key: index,
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.functionSelect(
                                                                                  item.title,
                                                                                  index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VListItemTitle,
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.title
                                                                                ) +
                                                                                  "\n                                                            "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.isServerModel &&
                                                        !_vm.isClazzModeling
                                                          ? _c(
                                                              VMenu,
                                                              {
                                                                staticClass:
                                                                  "pa-2",
                                                                attrs: {
                                                                  "open-on-click":
                                                                    "",
                                                                  "offset-y":
                                                                    "",
                                                                  left: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  VBtn,
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "gs-model-z-index-1 es-hide-replay-btn",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-right":
                                                                                            "5px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          text: "",
                                                                                          disabled:
                                                                                            _vm.disableBtn,
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.loadVersions()
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      VIcon,
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-restart"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "es-hide-replay",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Versions"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    3572690474
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  VList,
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        overflow:
                                                                          "hidden",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm.versionLists
                                                                      ? _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "text-align-last":
                                                                                      "center",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                VERSIONS\n                                                            "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              VDivider
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "overflow-y":
                                                                                      "scroll",
                                                                                    height:
                                                                                      "200px",
                                                                                  },
                                                                              },
                                                                              _vm._l(
                                                                                _vm.filteredVersionLists,
                                                                                function (
                                                                                  item,
                                                                                  index
                                                                                ) {
                                                                                  return _c(
                                                                                    VListItem,
                                                                                    {
                                                                                      key: index,
                                                                                      attrs:
                                                                                        {
                                                                                          "two-line":
                                                                                            "",
                                                                                          dense:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.moveToVersion(
                                                                                              item
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        VListItemContent,
                                                                                        [
                                                                                          _c(
                                                                                            VListItemTitle,
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-size":
                                                                                                    "medium",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                                                            " +
                                                                                                  _vm._s(
                                                                                                    item.version
                                                                                                  ) +
                                                                                                  "\n                                                                        "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            VListItemSubtitle,
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-size":
                                                                                                    "10px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.convertTimeStampToDate(
                                                                                                    item.timeStamp
                                                                                                  )
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              VDivider
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      VListItem,
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "-5px",
                                                                            "margin-bottom":
                                                                              "-10px",
                                                                            "text-align":
                                                                              "start",
                                                                          },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.saveComposition(
                                                                                "save"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          VListItemTitle,
                                                                          [
                                                                            _c(
                                                                              VIcon,
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .icon
                                                                                      .save
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              "\n                                                                CREATE VERSION\n                                                            "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      VListItem,
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "-5px",
                                                                            "margin-bottom":
                                                                              "-10px",
                                                                            "text-align":
                                                                              "start",
                                                                          },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.showReplay()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          VListItemTitle,
                                                                          [
                                                                            _c(
                                                                              VIcon,
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    small:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-restart"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              "REPLAY\n                                                            "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          VMenu,
                                                          {
                                                            staticClass: "pa-2",
                                                            attrs: {
                                                              "open-on-hover":
                                                                "",
                                                              "offset-y": "",
                                                              left: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _vm.isReadOnlyModel
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _c(
                                                                                VBtn,
                                                                                {
                                                                                  staticClass:
                                                                                    "gs-model-z-index-1 es-hide-fork-btn",
                                                                                  staticStyle:
                                                                                    {
                                                                                      "margin-right":
                                                                                        "5px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      text: "",
                                                                                      disabled:
                                                                                        _vm.disableBtn,
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.saveComposition(
                                                                                          "fork"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    VIcon,
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .icon
                                                                                            .fork
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "es-hide-fork",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                                    FORK\n                                                                "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              !_vm.projectVersion
                                                                                ? _c(
                                                                                    VBtn,
                                                                                    {
                                                                                      staticClass:
                                                                                        "gs-model-z-index-1 es-hide-join-btn",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-right":
                                                                                            "5px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            _vm
                                                                                              .joinRequestedText
                                                                                              .show
                                                                                              ? "primary"
                                                                                              : "success",
                                                                                          disabled:
                                                                                            _vm.disableBtn,
                                                                                          text: "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.requestInviteUser()
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm
                                                                                        .joinRequestedText
                                                                                        .show
                                                                                        ? _c(
                                                                                            "div",
                                                                                            [
                                                                                              _c(
                                                                                                VIcon,
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .icon
                                                                                                        .join
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "es-hide-join",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                    " +
                                                                                              _vm._s(
                                                                                                _vm
                                                                                                  .joinRequestedText
                                                                                                  .text
                                                                                              ) +
                                                                                              "\n                                                                "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            [
                                                                              _vm.showSaveBtn
                                                                                ? _c(
                                                                                    VBtn,
                                                                                    _vm._g(
                                                                                      {
                                                                                        staticClass:
                                                                                          "gs-model-z-index-1 es-hide-save-btn",
                                                                                        staticStyle:
                                                                                          {
                                                                                            "margin-right":
                                                                                              "5px",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            text: "",
                                                                                            disabled:
                                                                                              _vm.disableBtn,
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.saveComposition(
                                                                                                "save"
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _c(
                                                                                        VIcon,
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm
                                                                                                .icon
                                                                                                .save
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "es-hide-save",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                    SAVE\n                                                                "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _c(
                                                                                    VBtn,
                                                                                    _vm._g(
                                                                                      {
                                                                                        staticClass:
                                                                                          "gs-model-z-index-1",
                                                                                        staticStyle:
                                                                                          {
                                                                                            "margin-right":
                                                                                              "5px",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            text: "",
                                                                                            disabled:
                                                                                              _vm.disableBtn,
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.saveComposition(
                                                                                                "fork"
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _c(
                                                                                        VIcon,
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm
                                                                                                .icon
                                                                                                .fork
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "es-hide-fork",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                    FORK\n                                                                "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                    ]
                                                                  },
                                                                },
                                                              ]
                                                            ),
                                                          },
                                                          [
                                                            !_vm.isClazzModeling
                                                              ? _c(
                                                                  VList,
                                                                  _vm._l(
                                                                    _vm.filteredSaveItems,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        VListItem,
                                                                        {
                                                                          key: index,
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.functionSelect(
                                                                                  item.title,
                                                                                  index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VListItemTitle,
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.title
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                        _vm.isOwnModel &&
                                                        _vm.isServerModel &&
                                                        !_vm.isReadOnlyModel
                                                          ? _c(
                                                              VMenu,
                                                              {
                                                                attrs: {
                                                                  "offset-y":
                                                                    "",
                                                                  "open-on-hover":
                                                                    "",
                                                                  left: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  VBtn,
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "gs-model-z-index-1 es-hide-share-btn",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-right":
                                                                                            "5px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          text: "",
                                                                                          disabled:
                                                                                            !_vm.initLoad,
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openInviteUsers()
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      VIcon,
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .icon
                                                                                              .share
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "es-hide-share",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " SHARE "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm.requestCount
                                                                                      ? _c(
                                                                                          VAvatar,
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                "margin-left":
                                                                                                  "2px",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                size: "25",
                                                                                                color:
                                                                                                  "red",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                    " +
                                                                                                _vm._s(
                                                                                                  _vm.requestCount
                                                                                                ) +
                                                                                                "\n                                                                "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    2337655670
                                                                  ),
                                                              },
                                                              [
                                                                _c(
                                                                  VList,
                                                                  _vm._l(
                                                                    _vm.shareItems,
                                                                    function (
                                                                      item,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        VListItem,
                                                                        {
                                                                          key: index,
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.functionSelect(
                                                                                  item.title,
                                                                                  index
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VListItemTitle,
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.title
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          VMenu,
                                                          {
                                                            attrs: {
                                                              "offset-y": "",
                                                              "open-on-hover":
                                                                "",
                                                              left: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            VBtn,
                                                                            _vm._g(
                                                                              {
                                                                                staticClass:
                                                                                  "gs-model-z-index-1",
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-right":
                                                                                      "5px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "primary",
                                                                                    text: "",
                                                                                    disabled:
                                                                                      !_vm.initLoad,
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.openCodeViewer()
                                                                                    },
                                                                                },
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                VIcon,
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .icon
                                                                                        .code
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                                                    CODE\n                                                                "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm.ideWindow
                                                                                ? _c(
                                                                                    VProgressCircular,
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          indeterminate:
                                                                                            "",
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ]
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              VList,
                                                              {
                                                                staticStyle: {
                                                                  width:
                                                                    "175px",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.codeItems,
                                                                function (
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    VListItem,
                                                                    {
                                                                      key: index,
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.functionSelect(
                                                                              item.title,
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        VListItemTitle,
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.title
                                                                            ) +
                                                                              "\n                                                            "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "es-is-mobile" },
                                        [
                                          _c(
                                            VRow,
                                            {
                                              staticClass: "mobile-action-btn",
                                              staticStyle: {
                                                display: "flex",
                                                "margin-right": "50px",
                                              },
                                              attrs: {
                                                justify: "center",
                                                align: "start",
                                              },
                                            },
                                            [
                                              _c(VMenu, {
                                                staticClass: "pa-2",
                                                attrs: {
                                                  "open-on-hover": "",
                                                  "offset-y": "",
                                                  left: "",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              VBtn,
                                                              {
                                                                staticClass:
                                                                  "mobile-btn",
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "2px",
                                                                },
                                                                attrs: {
                                                                  text: "",
                                                                  disabled:
                                                                    _vm.disableBtn,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.openEmbeddedCanvas(
                                                                        "Kubernetes"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(VIcon, [
                                                                  _vm._v(
                                                                    "mdi-kubernetes"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ]),
                                              }),
                                              _vm.isServerModel &&
                                              !_vm.isClazzModeling
                                                ? _c(VMenu, {
                                                    staticClass: "pa-2",
                                                    attrs: {
                                                      text: "",
                                                      "open-on-hover": "",
                                                      "offset-y": "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    VBtn,
                                                                    {
                                                                      staticClass:
                                                                        "mobile-btn",
                                                                      attrs: {
                                                                        dark: "",
                                                                        small:
                                                                          "",
                                                                        disabled:
                                                                          _vm.disableBtn,
                                                                        text: "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.showReplay()
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        VIcon,
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-restart"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1149813644
                                                    ),
                                                  })
                                                : _vm._e(),
                                              _c(
                                                VMenu,
                                                {
                                                  attrs: {
                                                    "open-on-hover": "",
                                                    "offset-y": "",
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _vm.isReadOnlyModel &&
                                                          !_vm.projectVersion
                                                            ? _c(
                                                                VBtn,
                                                                {
                                                                  attrs: {
                                                                    text: "",
                                                                    color: _vm
                                                                      .joinRequestedText
                                                                      .show
                                                                      ? "primary"
                                                                      : "success",
                                                                    dark: "",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.requestInviteUser()
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm
                                                                    .joinRequestedText
                                                                    .show
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            VIcon,
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .icon
                                                                                    .join
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    "\n                                                    " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .joinRequestedText
                                                                          .text
                                                                      ) +
                                                                      "\n                                                "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm.isReadOnlyModel
                                                            ? _c(
                                                                VBtn,
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mobile-btn",
                                                                    attrs: {
                                                                      text: "",
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.saveComposition(
                                                                            "fork"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(VIcon, [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.icon
                                                                          .fork
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                VBtn,
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mobile-btn cp-es-save-btn",
                                                                    attrs: {
                                                                      text: "",
                                                                      small: "",
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.saveComposition(
                                                                            "save"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(VIcon, [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.icon
                                                                          .save
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                },
                                                [
                                                  !_vm.isClazzModeling
                                                    ? _c(
                                                        VList,
                                                        _vm._l(
                                                          _vm.filteredSaveItems,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              VListItem,
                                                              {
                                                                key: index,
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.functionSelect(
                                                                        item.title,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  VListItemTitle,
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.title
                                                                      ) +
                                                                        "\n                                                    "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm.isOwnModel &&
                                              _vm.isServerModel &&
                                              !_vm.isReadOnlyModel
                                                ? _c(
                                                    VMenu,
                                                    {
                                                      staticClass: "pa-2",
                                                      attrs: {
                                                        "offset-y": "",
                                                        "open-on-hover": "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      VBtn,
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "mobile-btn cp-es-share-btn",
                                                                          attrs:
                                                                            {
                                                                              text: "",
                                                                              dark: "",
                                                                              small:
                                                                                "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.openInviteUsers()
                                                                              },
                                                                          },
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          VIcon,
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .icon
                                                                                  .share
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm.requestCount
                                                                          ? _c(
                                                                              VAvatar,
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "margin-left":
                                                                                      "2px",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    size: "25",
                                                                                    color:
                                                                                      "red",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                            " +
                                                                                    _vm._s(
                                                                                      _vm.requestCount
                                                                                    ) +
                                                                                    "\n                                                        "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        839735093
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        VList,
                                                        _vm._l(
                                                          _vm.shareItems,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              VListItem,
                                                              {
                                                                key: index,
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.functionSelect(
                                                                        item.title,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  VListItemTitle,
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.title
                                                                      ) +
                                                                        "\n                                                    "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                VMenu,
                                                {
                                                  staticClass: "pa-2",
                                                  attrs: {
                                                    "open-on-hover": "",
                                                    "offset-y": "",
                                                    left: "",
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            [
                                                              _vm.isHexagonal
                                                                ? _c(
                                                                    VBtn,
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "gs-model-z-index-1 mobile-btn",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "2px",
                                                                          },
                                                                        attrs: {
                                                                          text: "",
                                                                          disabled:
                                                                            _vm.disableBtn,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.generateModel()
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        VIcon,
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-hexagon-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _c(
                                                                    VBtn,
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "gs-model-z-index-1 mobile-btn",
                                                                        staticStyle:
                                                                          {
                                                                            "margin-top":
                                                                              "2px",
                                                                          },
                                                                        attrs: {
                                                                          text: "",
                                                                          disabled:
                                                                            _vm.disableBtn,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.generateHexagonal()
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        VIcon,
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-view-dashboard"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                },
                                                [
                                                  _c(
                                                    VList,
                                                    _vm._l(
                                                      _vm.conversionItems,
                                                      function (item, index) {
                                                        return _c(
                                                          VListItem,
                                                          {
                                                            key: index,
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.functionSelect(
                                                                  item.title,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              VListItemTitle,
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  ) +
                                                                    "\n                                                    "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VMenu,
                                                {
                                                  attrs: {
                                                    "open-on-hover": "",
                                                    "offset-y": "",
                                                  },
                                                  scopedSlots: _vm._u([
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                VBtn,
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mobile-btn cp-es-code-viewer-btn",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-left":
                                                                          "10px",
                                                                      },
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      text: "",
                                                                      small: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.openCodeViewer()
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(VIcon, [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.icon
                                                                          .code
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ]),
                                                },
                                                [
                                                  _c(
                                                    VList,
                                                    _vm._l(
                                                      _vm.codeItems,
                                                      function (item, index) {
                                                        return _c(
                                                          VListItem,
                                                          {
                                                            key: index,
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.functionSelect(
                                                                  item.title,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              VListItemTitle,
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                              _c(
                                VCard,
                                {
                                  staticClass: "tools",
                                  staticStyle: {
                                    top: "100px",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { right: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "span",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "bpmn-icon-hand-tool",
                                                      class: {
                                                        icons:
                                                          !_vm.dragPageMovable,
                                                        hands:
                                                          _vm.dragPageMovable,
                                                      },
                                                      attrs: {
                                                        _width: "30",
                                                        _height: "30",
                                                      },
                                                      on: {
                                                        click: _vm.toggleGrip,
                                                      },
                                                    },
                                                    "span",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _vm.dragPageMovable == true
                                        ? _c("span", [
                                            _vm._v("Draggable Screen : on"),
                                          ])
                                        : _vm._e(),
                                      _vm.dragPageMovable == false
                                        ? _c("span", [
                                            _vm._v("Draggable Screen : off"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  !_vm.isReadOnlyModel
                                    ? _c(
                                        VTooltip,
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "gs-automatic-guidance-btn",
                                                            on: {
                                                              click:
                                                                _vm.automaticGuidanceChange,
                                                            },
                                                          },
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm.automaticGuidance ==
                                                        true
                                                          ? _c(
                                                              VIcon,
                                                              {
                                                                staticClass:
                                                                  "gs-automatic-guidance-icon",
                                                                staticStyle: {
                                                                  color:
                                                                    "#fbc02d",
                                                                },
                                                                attrs: {
                                                                  large: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-border-inside\n                                        "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.automaticGuidance ==
                                                        false
                                                          ? _c(
                                                              VIcon,
                                                              {
                                                                staticClass:
                                                                  "gs-automatic-guidance-icon",
                                                                attrs: {
                                                                  large: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-border-none\n                                        "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            73268207
                                          ),
                                        },
                                        [
                                          _vm.automaticGuidance == true
                                            ? _c("span", [
                                                _vm._v("Arrange Guidance : on"),
                                              ])
                                            : _vm._e(),
                                          _vm.automaticGuidance == false
                                            ? _c("span", [
                                                _vm._v(
                                                  "Arrange Guidance : off"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.elementTypes,
                                    function (item, key) {
                                      return _c(
                                        VTooltip,
                                        {
                                          key: key,
                                          attrs: {
                                            large: "",
                                            "nudge-top": "10",
                                            right: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "draggable",
                                                        attrs: {
                                                          align: "center",
                                                          _component:
                                                            item.component,
                                                          _width: item.width,
                                                          _height: item.height,
                                                        },
                                                      },
                                                      [
                                                        !_vm.isReadOnlyModel &&
                                                        (!_vm.isHexagonal ||
                                                          (_vm.isHexagonal &&
                                                            (item.component.includes(
                                                              "bounded-context"
                                                            ) ||
                                                              item.component.includes(
                                                                "packaged-business-capabilities"
                                                              ))))
                                                          ? _c(
                                                              "img",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "cp-sticker",
                                                                  attrs: {
                                                                    height:
                                                                      "30px",
                                                                    width:
                                                                      "30px",
                                                                    src: item.src,
                                                                  },
                                                                },
                                                                on
                                                              )
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.label)),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c(
                                VCard,
                                { staticClass: "mobile-first-sticker-tools" },
                                [
                                  _vm._l(
                                    _vm.elementTypes.slice(0, 5),
                                    function (item, key) {
                                      return _c(
                                        VTooltip,
                                        {
                                          key: key,
                                          attrs: {
                                            large: "",
                                            "nudge-top": "10",
                                            right: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "draggable",
                                                        staticStyle: {
                                                          margin:
                                                            "6px 6px 0px 6px",
                                                        },
                                                        attrs: {
                                                          align: "center",
                                                          _component:
                                                            item.component,
                                                          _width: item.width,
                                                          _height: item.height,
                                                        },
                                                      },
                                                      [
                                                        !_vm.isReadOnlyModel &&
                                                        (!_vm.isHexagonal ||
                                                          (_vm.isHexagonal &&
                                                            (item.component.includes(
                                                              "bounded-context"
                                                            ) ||
                                                              item.component.includes(
                                                                "packaged-business-capabilities"
                                                              ))))
                                                          ? _c(
                                                              "img",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    height:
                                                                      "30px",
                                                                    width:
                                                                      "30px",
                                                                    src: item.src,
                                                                  },
                                                                },
                                                                on
                                                              )
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.label)),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { right: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "span",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "bpmn-icon-hand-tool",
                                                      class: {
                                                        icons:
                                                          !_vm.dragPageMovable,
                                                        hands:
                                                          _vm.dragPageMovable,
                                                      },
                                                      attrs: {
                                                        _width: "30",
                                                        _height: "30",
                                                      },
                                                      on: {
                                                        click: _vm.toggleGrip,
                                                      },
                                                    },
                                                    "span",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _vm.dragPageMovable == true
                                        ? _c("span", [
                                            _vm._v("Draggable Screen : on"),
                                          ])
                                        : _vm._e(),
                                      _vm.dragPageMovable == false
                                        ? _c("span", [
                                            _vm._v("Draggable Screen : off"),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  !_vm.isReadOnlyModel
                                    ? _c(
                                        VTooltip,
                                        {
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "gs-automatic-guidance-btn",
                                                            on: {
                                                              click:
                                                                _vm.automaticGuidanceChange,
                                                            },
                                                          },
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm.automaticGuidance ==
                                                        true
                                                          ? _c(
                                                              VIcon,
                                                              {
                                                                staticClass:
                                                                  "gs-automatic-guidance-icon",
                                                                staticStyle: {
                                                                  color:
                                                                    "#fbc02d",
                                                                  "margin-top":
                                                                    "3px",
                                                                },
                                                                attrs: {
                                                                  large: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-border-inside"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm.automaticGuidance ==
                                                        false
                                                          ? _c(
                                                              VIcon,
                                                              {
                                                                staticClass:
                                                                  "gs-automatic-guidance-icon",
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "3px",
                                                                },
                                                                attrs: {
                                                                  large: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-border-none"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1062479420
                                          ),
                                        },
                                        [
                                          _vm.automaticGuidance == true
                                            ? _c("span", [
                                                _vm._v("Arrange Guidance : on"),
                                              ])
                                            : _vm._e(),
                                          _vm.automaticGuidance == false
                                            ? _c("span", [
                                                _vm._v(
                                                  "Arrange Guidance : off"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _c(
                                VCard,
                                { staticClass: "mobile-second-sticker-tools" },
                                _vm._l(
                                  _vm.elementTypes.slice(5, 12),
                                  function (item, key) {
                                    return _c(
                                      VTooltip,
                                      {
                                        key: key,
                                        attrs: {
                                          large: "",
                                          "nudge-top": "10",
                                          right: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "draggable",
                                                      staticStyle: {
                                                        margin:
                                                          "6px 6.5px 0px 5px",
                                                      },
                                                      attrs: {
                                                        align: "center",
                                                        _component:
                                                          item.component,
                                                        _width: item.width,
                                                        _height: item.height,
                                                      },
                                                    },
                                                    [
                                                      !_vm.isReadOnlyModel &&
                                                      (!_vm.isHexagonal ||
                                                        (_vm.isHexagonal &&
                                                          (item.component.includes(
                                                            "bounded-context"
                                                          ) ||
                                                            item.component.includes(
                                                              "packaged-business-capabilities"
                                                            ))))
                                                        ? _c(
                                                            "img",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  height:
                                                                    "30px",
                                                                  width: "30px",
                                                                  src: item.src,
                                                                },
                                                              },
                                                              on
                                                            )
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v(_vm._s(item.label))])]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.showParticipantPanel
                        ? _c("ParticipantPanel", {
                            attrs: { lists: _vm.participantLists },
                          })
                        : _vm._e(),
                      _c("model-canvas-share-dialog", {
                        attrs: {
                          showDialog: _vm.inviteDialog,
                          checkPublic: _vm.showPublicModel,
                          canvas: _vm.canvas,
                          canvasComponentName: "event-storming-model-canvas",
                        },
                        on: {
                          all: _vm.invitePublic,
                          apply: _vm.applyInviteUsers,
                          close: _vm.closeInviteUsers,
                          add: _vm.addInviteUser,
                          remove: _vm.removeInviteUser,
                        },
                        model: {
                          value: _vm.inviteLists,
                          callback: function ($$v) {
                            _vm.inviteLists = $$v
                          },
                          expression: "inviteLists",
                        },
                      }),
                      _c(
                        "div",
                        { key: _vm.autoModelingDialogKey },
                        [
                          _c("AutoModelingDialog", {
                            ref: "autoModelingDialog",
                            attrs: { showChat: _vm.showField, mode: "es" },
                            on: { changeFieldStatus: _vm.changeFieldStatus },
                          }),
                        ],
                        1
                      ),
                      _vm.generatorStep === "event" && _vm.projectId
                        ? _c(
                            "GeneratorUI",
                            {
                              key: "eventGenerator",
                              ref: "generatorUI",
                              attrs: {
                                projectId: _vm.projectId,
                                generatorStep: _vm.generatorStep,
                                defaultInputData:
                                  _vm.defaultGeneratorUiInputData,
                                modelValue: _vm.value,
                                tabs: _vm.tabs,
                                information: _vm.information,
                                mirrorValue: _vm.mirrorValue,
                              },
                              on: {
                                createModel: _vm.createModel,
                                modificateModel: _vm.modificateModel,
                                createModelFromDDL: _vm.createModelFromDDL,
                                clearModelValue: _vm.clearModelValue,
                                showContinueBtn: function ($event) {
                                  _vm.showContinue = true
                                },
                                isPauseQueue: _vm.setIsPauseQueue,
                              },
                            },
                            [
                              _vm.showContinue
                                ? _c(
                                    VTooltip,
                                    {
                                      attrs: { slot: "buttons", bottom: "" },
                                      slot: "buttons",
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "gs-es-auto-modling-btn",
                                                        staticStyle: {
                                                          padding: "0px 5px",
                                                          "margin-right":
                                                            "10px",
                                                        },
                                                        attrs: {
                                                          small: "",
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.generateAggregate()
                                                          },
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "span",
                                                      [
                                                        _vm._v("CONTINUE"),
                                                        _c(VIcon, [
                                                          _vm._v(
                                                            "mdi-arrow-right"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1210930871
                                      ),
                                    },
                                    [_c("span", [_vm._v("Generate Aggregate")])]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.generatorStep === "aggregate" && _vm.projectId
                        ? _c("GeneratorUI", {
                            key: "aggregateGenerator",
                            ref: "aggregateGeneratorUI",
                            attrs: {
                              projectId: _vm.projectId,
                              generator: "ESGenerator",
                              generatorParameter: _vm.generatorParameter,
                              generatorStep: _vm.generatorStep,
                              modelValue: _vm.value,
                              tabs: _vm.tabs,
                            },
                            on: {
                              createModel: _vm.createModel,
                              modificateModel: _vm.modificateModel,
                              createModelFromDDL: _vm.createModelFromDDL,
                              onGenerationFinished: _vm.onGenerationFinished,
                              clearModelValue: _vm.clearModelValue,
                              isPauseQueue: _vm.setIsPauseQueue,
                            },
                          })
                        : _vm._e(),
                      _vm.showUiWizard
                        ? _c(
                            "div",
                            [
                              _c("UIWizardDialoger", {
                                on: { createModel: _vm.onUiHintGenerated },
                                model: {
                                  value: _vm.value.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.value, "description", $$v)
                                  },
                                  expression: "value.description",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        VDialog,
                        {
                          attrs: {
                            persistent: "",
                            fullscreen: "",
                            "hide-overlay": "",
                            transition: "dialog-bottom-transition",
                          },
                          model: {
                            value: _vm.clusterDialog,
                            callback: function ($$v) {
                              _vm.clusterDialog = $$v
                            },
                            expression: "clusterDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VToolbar,
                                { attrs: { dark: "", color: "primary" } },
                                [
                                  _c(VToolbarTitle, [
                                    _vm._v("Manage Clusters"),
                                  ]),
                                  _c(VSpacer),
                                  _c(
                                    VToolbarItems,
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { icon: "", dark: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.clusterDialog = false
                                            },
                                          },
                                        },
                                        [_c(VIcon, [_vm._v("mdi-close")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VList,
                                { attrs: { "three-line": "", subheader: "" } },
                                [
                                  _c(
                                    VListItem,
                                    [
                                      _c(
                                        VListItemContent,
                                        [
                                          _c("clusters", {
                                            on: {
                                              close: function ($event) {
                                                _vm.clusterDialog = false
                                              },
                                            },
                                            model: {
                                              value: _vm.clusterInfo,
                                              callback: function ($$v) {
                                                _vm.clusterInfo = $$v
                                              },
                                              expression: "clusterInfo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("model-storage-dialog", {
                        attrs: {
                          condition: _vm.storageCondition,
                          showDialog: _vm.showStorageDialog,
                          isClazzModeling: _vm.isClazzModeling,
                        },
                        on: {
                          updateClassModelingId: _vm.updateClassModelingId,
                          save: _vm.saveModel,
                          fork: _vm.forkModel,
                          backup: _vm.backupModel,
                          close: _vm.storageDialogCancel,
                        },
                      }),
                      _c("dialog-purchase-item", {
                        attrs: {
                          "purchase-item-info": _vm.purchaseItemDialogInfo,
                        },
                        on: {
                          result: _vm.purchaseItemDialogSubmit,
                          close: _vm.purchaseItemDialogClose,
                        },
                        model: {
                          value: _vm.purchaseItemDialog,
                          callback: function ($$v) {
                            _vm.purchaseItemDialog = $$v
                          },
                          expression: "purchaseItemDialog",
                        },
                      }),
                      _c(
                        VDialog,
                        {
                          attrs: { "max-width": "550" },
                          on: {
                            "click:outside": function ($event) {
                              return _vm.closeResourceQuota()
                            },
                          },
                          model: {
                            value: _vm.resourceQuotaDialog,
                            callback: function ($$v) {
                              _vm.resourceQuotaDialog = $$v
                            },
                            expression: "resourceQuotaDialog",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "text-align": "-webkit-center" } },
                            [
                              _c("IDEResourceDialog", {
                                attrs: { namespace: _vm.hashName },
                                on: { submit: _vm.closeResourceQuota },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: { "max-width": "290" },
                          model: {
                            value: _vm.tokenDialog,
                            callback: function ($$v) {
                              _vm.tokenDialog = $$v
                            },
                            expression: "tokenDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(VCardTitle, { staticClass: "headline" }, [
                                _vm._v("Token Dialog"),
                              ]),
                              _c(
                                VCardText,
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      label: "Cluster Address",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.clusterAddress,
                                      callback: function ($$v) {
                                        _vm.clusterAddress = $$v
                                      },
                                      expression: "clusterAddress",
                                    },
                                  }),
                                  _c(VTextField, {
                                    attrs: {
                                      label: "Kubernetes Token",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.kubernetesToken,
                                      callback: function ($$v) {
                                        _vm.kubernetesToken = $$v
                                      },
                                      expression: "kubernetesToken",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "green darken-1",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.tokenSave()
                                        },
                                      },
                                    },
                                    [_vm._v("Download")]
                                  ),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "red darken-1",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.tokenDialog = false
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: { "max-width": "290" },
                          model: {
                            value: _vm.forkAlertDialog,
                            callback: function ($$v) {
                              _vm.forkAlertDialog = $$v
                            },
                            expression: "forkAlertDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VCardTitle,
                                { staticClass: "headline" },
                                [
                                  _vm._v("Fork\n                            "),
                                  _c(VIcon, [_vm._v(_vm._s(_vm.icon.fork))]),
                                ],
                                1
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  "\n                            권한이 없어서 수정 할 수 없습니다. Fork를 하여\n                            사용해 주세요."
                                ),
                              ]),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "green darken-1",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveComposition("fork")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Fork\n                            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "red darken-1",
                                        text: "",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          _vm.forkAlertDialog = false
                                        },
                                      },
                                    },
                                    [_vm._v("Close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: { "max-width": "500" },
                          model: {
                            value: _vm.projectIdeAlertDialog,
                            callback: function ($$v) {
                              _vm.projectIdeAlertDialog = $$v
                            },
                            expression: "projectIdeAlertDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(VCardTitle, { staticClass: "headline" }, [
                                _vm._v("Warning"),
                              ]),
                              _c(VCardText, [
                                _vm._v(
                                  "\n                            There are\n                            "
                                ),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(_vm._s(_vm.errorCount)),
                                ]),
                                _vm._v(
                                  "\n                            elements that would be problematic for building the project."
                                ),
                                _c("br"),
                                _vm._v(
                                  "You can continue with this warning.\n                        "
                                ),
                              ]),
                              _c(
                                VCardActions,
                                [
                                  _c(
                                    VList,
                                    [
                                      _c(
                                        VListGroup,
                                        {
                                          attrs: { "no-action": "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    VListItemContent,
                                                    [
                                                      _c(VListItemTitle, [
                                                        _vm._v("See details"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        _vm._l(_vm.errorList, function (item) {
                                          return _c(
                                            VListItem,
                                            {
                                              key: item.code,
                                              staticStyle: {
                                                "margin-left": "-12%",
                                              },
                                            },
                                            [
                                              _c(
                                                VListItemContent,
                                                [
                                                  _c(
                                                    VListItemTitle,
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          staticStyle: {
                                                            "margin-right":
                                                              "2%",
                                                          },
                                                          attrs: {
                                                            color:
                                                              _vm
                                                                .validationLevelIcon[
                                                                item.level
                                                              ].color,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm
                                                                .validationLevelIcon[
                                                                item.level
                                                              ].icon
                                                            ) +
                                                              "\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "\n                                                [ " +
                                                          _vm._s(item.eleName) +
                                                          " ] " +
                                                          _vm._s(item.msg) +
                                                          "\n                                            "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("br"),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-left": "58%",
                                    "padding-bottom": "10px",
                                  },
                                },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { text: "" },
                                      nativeOn: {
                                        click: function ($event) {
                                          _vm.projectIdeAlertDialog = false
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { color: "primary", text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.openIdeAccept(),
                                            _vm.functionSelect("Project IDE")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "Open IDE\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: {
                            "no-click-animation": "",
                            persistent: "",
                            fullscreen: "",
                            "hide-overlay": "",
                            transition: "dialog-bottom-transition",
                          },
                          model: {
                            value: _vm.embeddedCanvasDialog,
                            callback: function ($$v) {
                              _vm.embeddedCanvasDialog = $$v
                            },
                            expression: "embeddedCanvasDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _vm.embeddedCanvasType == "Domain Class Modeling"
                                ? _c("Icon", {
                                    staticClass: "gs-icon-style",
                                    staticStyle: {
                                      "margin-right": "2px",
                                      height: "50px",
                                      width: "50px",
                                      color: "#1e88e5",
                                      position: "fixed",
                                      "z-index": "1",
                                      top: "15px",
                                      left: "20px",
                                    },
                                    attrs: {
                                      icon: "fluent-mdl2:modeling-view",
                                    },
                                  })
                                : _vm.embeddedCanvasType == "Kubernetes"
                                ? _c(
                                    VIcon,
                                    {
                                      staticStyle: {
                                        color: "#1e88e5",
                                        position: "fixed",
                                        "z-index": "1",
                                        top: "15px",
                                        left: "20px",
                                      },
                                      attrs: { size: "55" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeEmbeddedCanvas()
                                        },
                                      },
                                    },
                                    [_vm._v("mdi-kubernetes")]
                                  )
                                : _vm._e(),
                              _c(
                                VBtn,
                                {
                                  staticStyle: {
                                    position: "fixed",
                                    "z-index": "1",
                                    right: "20px",
                                    top: "20px",
                                    color: "gray",
                                  },
                                  attrs: { icon: "", dark: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.closeEmbeddedCanvas()
                                    },
                                  },
                                },
                                [_c(VIcon, [_vm._v("mdi-close")])],
                                1
                              ),
                              _vm.embeddedCanvasType == "Domain Class Modeling"
                                ? _c(
                                    VLayout,
                                    [
                                      _c("uml-class-model-canvas", {
                                        attrs: {
                                          embedded: true,
                                          aggregateRootList:
                                            _vm.aggregateRootList,
                                          esValue: _vm.value,
                                          projectId: _vm.projectId,
                                        },
                                        model: {
                                          value: _vm.embeddedCanvasValue,
                                          callback: function ($$v) {
                                            _vm.embeddedCanvasValue = $$v
                                          },
                                          expression: "embeddedCanvasValue",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.embeddedCanvasType == "Kubernetes"
                                ? _c(
                                    VLayout,
                                    [
                                      _c("kubernetes-model-canvas", {
                                        attrs: {
                                          embedded: true,
                                          projectId: _vm.projectId,
                                          projectName: _vm.projectName,
                                          isOwnModel: _vm.isOwnModel,
                                          isReadOnlyModel: _vm.isReadOnlyModel,
                                          modelingProjectId: _vm.projectId,
                                          projectVersion: _vm.projectVersion,
                                        },
                                        on: {
                                          changedByMe: _vm.settingChangedByMe,
                                        },
                                        model: {
                                          value: _vm.embeddedCanvasInitValue,
                                          callback: function ($$v) {
                                            _vm.embeddedCanvasInitValue = $$v
                                          },
                                          expression: "embeddedCanvasInitValue",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: { "max-width": "500" },
                          model: {
                            value: _vm.settingExportDialog,
                            callback: function ($$v) {
                              _vm.settingExportDialog = $$v
                            },
                            expression: "settingExportDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VCardTitle,
                                { staticStyle: { color: "#757575" } },
                                [
                                  _vm._v(
                                    "\n                            Export as a PBC\n                        "
                                  ),
                                ]
                              ),
                              _c(
                                VCardText,
                                [
                                  _vm.publicPBCElements
                                    ? _c(
                                        VList,
                                        _vm._l(
                                          _vm.publicPBCElements,
                                          function (elements, key) {
                                            return _c(
                                              VListGroup,
                                              {
                                                attrs: { value: true },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            VListItemTitle,
                                                            [
                                                              _vm._v(
                                                                "Select Public\n                                            " +
                                                                  _vm._s(key)
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              _vm._l(
                                                elements,
                                                function (element) {
                                                  return _c(
                                                    VListItem,
                                                    {
                                                      key: element.elementView
                                                        .id,
                                                    },
                                                    [
                                                      _c(
                                                        VListItemAction,
                                                        [
                                                          _c(VCheckbox, {
                                                            model: {
                                                              value:
                                                                element.selectedPBC,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    element,
                                                                    "selectedPBC",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "\n                                                    element.selectedPBC\n                                                ",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        VListItemContent,
                                                        [
                                                          _c(
                                                            VListItemTitle,
                                                            {
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    element.name
                                                                  ),
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "green darken-1",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.applyExportDialog()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Apply\n                            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "red darken-1",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeExportDialog()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Close\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: { "max-width": "500" },
                          model: {
                            value: _vm.bpmnDialog,
                            callback: function ($$v) {
                              _vm.bpmnDialog = $$v
                            },
                            expression: "bpmnDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(VCardTitle, [_vm._v("Select Process")]),
                              _c(
                                VCardText,
                                _vm._l(_vm.bpmnCommands, function (command) {
                                  return _c(
                                    VListItem,
                                    {
                                      key: command.elementView.id,
                                      on: {
                                        click: function ($event) {
                                          return _vm.conversionBpmn(command)
                                        },
                                      },
                                    },
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(_vm._s(command.name)),
                                      ]),
                                      _c(
                                        VListItemIcon,
                                        [
                                          _c(VIcon, [
                                            _vm._v("mdi-open-in-new"),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "red darken-1",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.bpmnDialog = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Close\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          staticStyle: { height: "100%" },
                          attrs: { persistent: "", scrollable: "" },
                          model: {
                            value: _vm.modelingListsDialog,
                            callback: function ($$v) {
                              _vm.modelingListsDialog = $$v
                            },
                            expression: "modelingListsDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            { staticStyle: { height: "100%" } },
                            [
                              _c(
                                VCardTitle,
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    top: "-10px",
                                  },
                                },
                                [_vm._v("Select Model for PBC")]
                              ),
                              _c(
                                VCardActions,
                                {
                                  staticStyle: {
                                    "justify-content": "flex-end",
                                  },
                                },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { small: "", text: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeModelingListsDialog()
                                        },
                                      },
                                    },
                                    [
                                      _c(VIcon, { attrs: { small: "" } }, [
                                        _vm._v("mdi-close"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                [
                                  _c("PBCModelList", {
                                    attrs: { pbc: _vm.modelingPBCElement },
                                    on: {
                                      "selected-model":
                                        _vm.applyModelingListsDialog,
                                      close: _vm.closeModelingListsDialog,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("modeler-image-generator", {
                        ref: "modeler-image-generator",
                      }),
                      _c(
                        "hsc-window-style-metal",
                        [
                          _c(
                            "hsc-window",
                            {
                              staticStyle: {
                                color: "rgb(0, 0, 0)",
                                "box-shadow": "rgba(0, 0, 0, 0.5) 0px 4pt 8pt",
                                background: "linear-gradient(",
                                "z-index": "0",
                                overflow: "visible",
                                width: "404px",
                                height: "154.2px",
                              },
                              attrs: {
                                title: "User Camera",
                                closeButton: true,
                                isOpen: _vm.webRtcDialog,
                                resizable: true,
                                isScrollable: true,
                                minWidth: 400,
                                minHeight: 110,
                                maxWidth: 800,
                                maxHeight: 110,
                                positionHint: "5 / -10",
                              },
                              on: {
                                "update:isOpen": function ($event) {
                                  _vm.webRtcDialog = $event
                                },
                                "update:is-open": function ($event) {
                                  _vm.webRtcDialog = $event
                                },
                              },
                            },
                            [
                              _c(
                                VLayout,
                                [
                                  _c(
                                    VCol,
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticStyle: {
                                            "margin-left": "2px",
                                            "margin-bottom": "2px",
                                          },
                                        },
                                        [
                                          _c("vue-webrtc", {
                                            ref: "webrtc",
                                            staticClass: "video-list",
                                            attrs: {
                                              width: "100%",
                                              height: "160px",
                                              cameraHeight: "50px",
                                              enableAudio: "",
                                              roomId: _vm.rtcRoomId,
                                            },
                                            on: { error: _vm.onError },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "two",
              fn: function () {
                return [
                  _c("CodeGenerator", {
                    attrs: {
                      isOwnModel: _vm.isOwnModel,
                      isServerModel: _vm.isServerModel,
                      projectInformation: _vm.information,
                      projectName: _vm.projectName,
                      modelInitLoad: _vm.initLoad,
                      modelingProjectId: _vm.projectId,
                      asyncCodeForValue: false,
                      callCodeForValue: _vm.changedTemplateCode,
                      oldTreeHashLists: _vm.oldTreeHashLists,
                      newTreeHashLists: _vm.newTreeHashLists,
                      projectVersion: _vm.projectVersion,
                      generatorStep: _vm.generatorStep,
                      userInfo: _vm.userInfo,
                      "canvas-name": "event-storming-model-canvas",
                    },
                    on: {
                      "update:oldTreeHashLists": function ($event) {
                        _vm.oldTreeHashLists = $event
                      },
                      "update:old-tree-hash-lists": function ($event) {
                        _vm.oldTreeHashLists = $event
                      },
                      "update:newTreeHashLists": function ($event) {
                        _vm.newTreeHashLists = $event
                      },
                      "update:new-tree-hash-lists": function ($event) {
                        _vm.newTreeHashLists = $event
                      },
                      changedByMe: _vm.settingChangedByMe,
                      editModelData: _vm.editModelData,
                      setInformation: _vm.setInformation,
                    },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c("GitInformation", {
        attrs: { git: _vm.gitURLforModel },
        on: {
          close: function ($event) {
            return _vm.closeGitInfo()
          },
          "update:git": function ($event) {
            _vm.gitURLforModel = $event
          },
        },
        model: {
          value: _vm.gitInfoDialog,
          callback: function ($$v) {
            _vm.gitInfoDialog = $$v
          },
          expression: "gitInfoDialog",
        },
      }),
      _vm._l(_vm.filteredMouseEventHandlers, function (otherMouseEvent, email) {
        return _c(
          "div",
          { key: email },
          [
            _c("MouseCursorComponent", {
              attrs: { mouseEvent: otherMouseEvent, email: email },
            }),
          ],
          1
        )
      }),
      _c(
        VDialog,
        {
          model: {
            value: _vm.showLoginCard,
            callback: function ($$v) {
              _vm.showLoginCard = $$v
            },
            expression: "showLoginCard",
          },
        },
        [
          _c("Login", {
            attrs: { onlyGitLogin: true },
            on: {
              login: function ($event) {
                _vm.showLoginCard = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        VDialog,
        {
          staticStyle: { height: "100%" },
          attrs: { persistent: "", width: "300", scrollable: "" },
          model: {
            value: _vm.mirrorElementDialog,
            callback: function ($$v) {
              _vm.mirrorElementDialog = $$v
            },
            expression: "mirrorElementDialog",
          },
        },
        [
          _c(
            VCard,
            { staticStyle: { height: "100%" } },
            [
              _c(VCardTitle, [_vm._v(" Select for mirror element")]),
              _c(
                VCardText,
                [
                  _c(VAutocomplete, {
                    attrs: {
                      label: "BoundedContext",
                      "item-text": "name",
                      "item-value": "elementView.id",
                      items: _vm.filteredBCForMirror,
                      "auto-select-first": "",
                    },
                    model: {
                      value: _vm.mirrorElementInfo.selectedBC,
                      callback: function ($$v) {
                        _vm.$set(_vm.mirrorElementInfo, "selectedBC", $$v)
                      },
                      expression: "mirrorElementInfo.selectedBC",
                    },
                  }),
                  _vm.filteredAggregateForMirror
                    ? _c(VAutocomplete, {
                        attrs: {
                          label: "Aggregate",
                          "item-text": "name",
                          "item-value": "elementView.id",
                          items: _vm.filteredAggregateForMirror,
                          "auto-select-first": "",
                        },
                        model: {
                          value: _vm.mirrorElementInfo.selectedAgg,
                          callback: function ($$v) {
                            _vm.$set(_vm.mirrorElementInfo, "selectedAgg", $$v)
                          },
                          expression: "mirrorElementInfo.selectedAgg",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                VCardActions,
                { staticStyle: { "justify-content": "right" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.applySelectedMirrorElement()
                        },
                      },
                    },
                    [_vm._v("Select")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: {
            "max-width": "1200",
            "max-height": "800",
            overflow: "scroll",
          },
          model: {
            value: _vm.showDDLDraftDialog,
            callback: function ($$v) {
              _vm.showDDLDraftDialog = $$v
            },
            expression: "showDDLDraftDialog",
          },
        },
        [
          _c("ModelDraftDialog", {
            attrs: { DDLDraftTable: _vm.DDLDraftTable },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
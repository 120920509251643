import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-panel",
    {
      attrs: {
        image: _vm.image,
        "is-read-only": _vm.isReadOnly,
        "width-style": _vm.widthStyle,
        "related-url": _vm.relatedUrl,
        "validation-lists": _vm.validationLists,
        "translate-obj": _vm.translateObj,
        "element-author-display": _vm.elementAuthorDisplay,
      },
      on: { close: _vm.closePanel, changeTranslate: _vm.changeTranslate },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("template", { slot: "element" }, [
        _c(
          "div",
          [
            _vm.openExample
              ? _c("RuleExampleDialog", {
                  on: {
                    closeExampleDialog: function ($event) {
                      return _vm.closeExampleDialog()
                    },
                  },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                })
              : _vm._e(),
            _c(
              VCard,
              { attrs: { flat: "" } },
              [
                _c(
                  VCardText,
                  [
                    _c(VCheckbox, {
                      attrs: { label: "Saga" },
                      model: {
                        value: _vm.value.isSaga,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "isSaga", $$v)
                        },
                        expression: "value.isSaga",
                      },
                    }),
                    _vm.value.isSaga
                      ? _c("event-storming-attribute", {
                          attrs: {
                            isReadOnly: _vm.isReadOnly,
                            type: _vm.value._type,
                            elementId: _vm.value.elementView.id,
                          },
                          model: {
                            value: _vm.value.fieldDescriptors,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "fieldDescriptors", $$v)
                            },
                            expression: "value.fieldDescriptors",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("template", { slot: "t-description-text" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.$t("panelInfo.PolicyDefinitionPanel")) +
            "\n    "
        ),
      ]),
      _c("template", { slot: "t-generation-text" }, [
        _vm._v(
          '\n        Policies become the "Ports and Adaptors for Aggregate Root".\n    '
        ),
      ]),
      _c("template", { slot: "t-edit-user" }, [
        _vm.newEditUserImg.length > 0 && _vm.isReadOnly
          ? _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  VChip,
                  {
                    staticStyle: { "font-weight": "bold" },
                    attrs: {
                      small: "",
                      color: "orange",
                      "text-color": "white",
                    },
                    on: {
                      "~click": function ($event) {
                        return _vm.forceEditPanel()
                      },
                    },
                  },
                  [
                    _c(
                      VAvatar,
                      { attrs: { left: "" } },
                      [_c(VIcon, [_vm._v("mdi-lead-pencil")])],
                      1
                    ),
                    _c(VRow, [
                      _c("div", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.newEditUserImg[0].name) +
                            " is now editing..."
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "margin-right": "10px",
                          },
                        },
                        [_vm._v(" ( Click to force editing ) ")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "template",
        { slot: "md-title-side" },
        [
          _c(
            VBtn,
            {
              staticStyle: { "margin-left": "10px", "margin-top": "-12px" },
              attrs: {
                text: "",
                color: "primary",
                disabled: _vm.isReadOnly || !_vm.exampleAvailable,
              },
              on: {
                click: function ($event) {
                  return _vm.openExampleDialog()
                },
              },
            },
            [_vm._v("Examples")]
          ),
          !_vm.exampleAvailable
            ? _c(
                VTooltip,
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              VBtn,
                              _vm._g(
                                _vm._b(
                                  {
                                    staticStyle: {
                                      "margin-left": "-8px",
                                      "margin-top": "-15px",
                                      width: "10px",
                                      height: "10px",
                                    },
                                    attrs: { icon: "" },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c(
                                  VIcon,
                                  { attrs: { color: "grey lighten-1" } },
                                  [_vm._v("mdi-help-circle")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3283573148
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n                The following steps are required to use the 'EXAMPLES'. "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                1. There must be a policy sticker that forms the event sticker and outgoingRelation. "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                2. A relationship with the event that occurs through the policy must be formed. "
                    ),
                    _c("br"),
                    _vm._v("\n                e.g."),
                    _c("br"),
                    _c("img", {
                      attrs: {
                        width: "795",
                        alt: "image",
                        src: "https://github.com/user-attachments/assets/dc66113d-516b-42a5-b717-c8365abecd7e",
                      },
                    }),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "common-panel",
    {
      attrs: {
        image: _vm.image,
        "is-read-only": _vm.isReadOnly,
        "width-style": _vm.widthStyle,
        "related-url": _vm.relatedUrl,
        "validation-lists": _vm.validationLists,
        "translate-obj": _vm.translateObj,
        "element-author-display": _vm.elementAuthorDisplay,
      },
      on: { close: _vm.closePanel, changeTranslate: _vm.changeTranslate },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c("template", { slot: "t-description-text" }, [
        _vm._v(_vm._s(_vm.$t("panelInfo.UIDefinitionPanel"))),
      ]),
      _c("template", { slot: "t-edit-user" }, [
        _vm.newEditUserImg.length > 0 && _vm.isReadOnly
          ? _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  VChip,
                  {
                    staticStyle: { "font-weight": "bold" },
                    attrs: {
                      small: "",
                      color: "orange",
                      "text-color": "white",
                    },
                    on: {
                      "~click": function ($event) {
                        return _vm.forceEditPanel()
                      },
                    },
                  },
                  [
                    _c(
                      VAvatar,
                      { attrs: { left: "" } },
                      [_c(VIcon, [_vm._v("mdi-lead-pencil")])],
                      1
                    ),
                    _c(VRow, [
                      _c("div", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.newEditUserImg[0].name) +
                            " is now editing..."
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "margin-right": "10px",
                          },
                        },
                        [_vm._v(" ( Click to force editing ) ")]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("template", { slot: "element" }, [
        _c(
          "div",
          [
            _c(
              VCard,
              { attrs: { flat: "" } },
              [
                _c(VCardText, { staticClass: "panel-title" }, [
                  _vm._v("UI Type"),
                ]),
                _c(VCardText, [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    _vm._l(_vm.uiTypeList, function (item) {
                      return _c(VCheckbox, {
                        key: item,
                        staticClass: "mr-auto",
                        attrs: { label: item, value: item },
                        model: {
                          value: _vm.value.uiType,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "uiType", $$v)
                          },
                          expression: "value.uiType",
                        },
                      })
                    }),
                    1
                  ),
                ]),
              ],
              1
            ),
            _vm.value.uiType === "Chart"
              ? _c(
                  VCard,
                  { attrs: { flat: "" } },
                  [
                    _c(VCardText, [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("div", { staticClass: "panel-title mr-auto" }, [
                            _vm._v("Chart"),
                          ]),
                          _vm.recommending
                            ? _c(VProgressCircular, {
                                staticClass: "mr-2",
                                attrs: { indeterminate: "", color: "primary" },
                              })
                            : _vm._e(),
                          _c(
                            VBtn,
                            {
                              attrs: {
                                disabled: _vm.recommending,
                                color: "primary",
                              },
                              on: { click: _vm.recommend },
                            },
                            [
                              _vm._v(
                                "\n                            guide me\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.recommendData.length > 0
                      ? _c(
                          "div",
                          _vm._l(_vm.recommendData, function (item, index) {
                            return _c(
                              VCard,
                              {
                                key: index,
                                staticClass: "mx-5",
                                attrs: { outlined: "" },
                              },
                              [
                                _c(
                                  VCardTitle,
                                  { staticClass: "subtitle-1" },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(item.chartType) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                                _c(VCardText, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item.reason) +
                                      "\n                        "
                                  ),
                                ]),
                                _c(
                                  VCardActions,
                                  [
                                    _c(VSpacer),
                                    _c(
                                      VBtn,
                                      {
                                        attrs: { color: "success" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.getFieldMapping(
                                              item.chartType
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Apply\n                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                    _c(
                      VCardText,
                      [
                        _c(VSelect, {
                          attrs: {
                            items: _vm.chartList,
                            label: "Type",
                            outlined: "",
                            dense: "",
                          },
                          model: {
                            value: _vm.value.chart.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.chart, "type", $$v)
                            },
                            expression: "value.chart.type",
                          },
                        }),
                        _vm.value.chart.type !== ""
                          ? _c(
                              "div",
                              [
                                _c(VSelect, {
                                  attrs: {
                                    label: "Category (x-axis)",
                                    items: _vm.readModelFieldDescriptors,
                                    "item-text": "name",
                                    "item-value": "name",
                                    multiple: "",
                                    outlined: "",
                                    dense: "",
                                  },
                                  model: {
                                    value:
                                      _vm.value.chart.fieldMapping.category,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.value.chart.fieldMapping,
                                        "category",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "value.chart.fieldMapping.category",
                                  },
                                }),
                                _c(VSelect, {
                                  attrs: {
                                    label: "Data (y-axis)",
                                    items: _vm.readModelFieldDescriptors,
                                    "item-text": "name",
                                    "item-value": "name",
                                    multiple: "",
                                    outlined: "",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.value.chart.fieldMapping.data,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.value.chart.fieldMapping,
                                        "data",
                                        $$v
                                      )
                                    },
                                    expression: "value.chart.fieldMapping.data",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm.value.uiType === "Grid"
              ? _c(
                  VCard,
                  { attrs: { flat: "" } },
                  [
                    _c(VCardText, [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("div", { staticClass: "panel-title mr-auto" }, [
                            _vm._v("Grid"),
                          ]),
                          _vm.recommending
                            ? _c(VProgressCircular, {
                                staticClass: "mr-2",
                                attrs: { indeterminate: "", color: "primary" },
                              })
                            : _vm._e(),
                          _c(
                            VBtn,
                            {
                              attrs: {
                                disabled: _vm.recommending,
                                color: "primary",
                              },
                              on: { click: _vm.recommend },
                            },
                            [
                              _vm._v(
                                "\n                            guide me\n                        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      VCardText,
                      [
                        _c(VSelect, {
                          attrs: {
                            label: "Columns",
                            items: _vm.readModelFieldDescriptors,
                            "item-text": "name",
                            "item-value": "name",
                            multiple: "",
                            outlined: "",
                            dense: "",
                          },
                          model: {
                            value: _vm.value.grid.columns,
                            callback: function ($$v) {
                              _vm.$set(_vm.value.grid, "columns", $$v)
                            },
                            expression: "value.grid.columns",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm.value.uiType === "Card"
              ? _c(
                  VCard,
                  { attrs: { flat: "" } },
                  [
                    _c(VCardText, { staticClass: "panel-title" }, [
                      _vm._v("\n                    Card\n                "),
                    ]),
                    _c(
                      VCardText,
                      [
                        _c(
                          VCard,
                          [
                            _c(
                              VCardTitle,
                              { staticClass: "d-flex" },
                              [
                                _c(VSelect, {
                                  staticStyle: {
                                    "max-width": "250px",
                                    "margin-right": "auto",
                                  },
                                  attrs: {
                                    items: _vm.readModelFieldDescriptors,
                                    "item-text": "name",
                                    "item-value": "name",
                                    label: "Title Field",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.value.card.title,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.value.card, "title", $$v)
                                    },
                                    expression: "value.card.title",
                                  },
                                }),
                                _c(VCheckbox, {
                                  staticStyle: { "min-width": "140px" },
                                  attrs: { label: "Use Sub Title", dense: "" },
                                  model: {
                                    value: _vm.useSubtitle,
                                    callback: function ($$v) {
                                      _vm.useSubtitle = $$v
                                    },
                                    expression: "useSubtitle",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.useSubtitle
                              ? _c(
                                  VCardSubtitle,
                                  [
                                    _c(VSelect, {
                                      attrs: {
                                        items: _vm.readModelFieldDescriptors,
                                        "item-text": "name",
                                        "item-value": "name",
                                        label: "Sub-Title Field",
                                        dense: "",
                                      },
                                      model: {
                                        value: _vm.value.card.subtitle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.value.card,
                                            "subtitle",
                                            $$v
                                          )
                                        },
                                        expression: "value.card.subtitle",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              VCardText,
                              [
                                _c(VSelect, {
                                  attrs: {
                                    items: _vm.readModelFieldDescriptors,
                                    "item-text": "name",
                                    "item-value": "name",
                                    label: "Text Field",
                                    dense: "",
                                  },
                                  model: {
                                    value: _vm.value.card.text,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.value.card, "text", $$v)
                                    },
                                    expression: "value.card.text",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
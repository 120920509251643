var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "geometry-element",
        {
          attrs: {
            selectable: _vm.selectable,
            movable: _vm.movable,
            resizable: _vm.resizable,
            deletable: _vm.deletable,
            connectable: "",
            id: _vm.value.elementView.id,
            x: _vm.elementCoordinate.x,
            y: _vm.elementCoordinate.y,
            width: _vm.elementCoordinate.width,
            height: _vm.elementCoordinate.height,
            angle: _vm.elementCoordinate.angle,
            customMoveActionExist:
              _vm.canvas.isCustomMoveExist && !_vm.isPBCModel,
            label:
              _vm.getFieldDescriptors || _vm.canvas.isHexagonal
                ? ""
                : _vm.getNamePanel,
            _style: {
              "label-angle": _vm.elementCoordinate.angle,
              "font-weight": "bold",
              "font-size": "16",
            },
          },
          on: {
            "update:id": function ($event) {
              return _vm.$set(_vm.value.elementView, "id", $event)
            },
            "update:x": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "x", $event)
            },
            "update:y": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "y", $event)
            },
            "update:width": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "width", $event)
            },
            "update:height": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "height", $event)
            },
            "update:angle": function ($event) {
              return _vm.$set(_vm.elementCoordinate, "angle", $event)
            },
            customMoveAction: _vm.delayedMove,
            moveShape: _vm.onMoveShape,
            removeShape: _vm.onRemoveShape,
            dblclick: _vm.openPanel,
            selectShape: _vm.selectedActivity,
            deSelectShape: _vm.deSelectedActivity,
            rotateShape: _vm.onRotateShape,
            addedToGroup: _vm.onAddedToGroup,
          },
        },
        [
          _vm.canvas.isHexagonal
            ? _c("geometry-circle", {
                attrs: {
                  center: [50, 50],
                  radius: 50,
                  _style: {
                    "stroke-width": 20,
                    stroke: "#F1A746",
                    fill: "#F1A746",
                    "fill-opacity": 1,
                    r: "1",
                    "z-index": "998",
                  },
                },
              })
            : _vm._e(),
          _vm.canvas.isHexagonal
            ? _c(
                "sub-elements",
                [
                  _c("text-element", {
                    attrs: {
                      "sub-top": "-20px",
                      "sub-right": "100%",
                      "sub-width": 150,
                      text: _vm.getNamePanel,
                      subStyle: {
                        "font-color": "#F1A746",
                        "font-weight": "bold",
                        "font-size": "16",
                        "text-anchor": "end",
                        "z-index": "998",
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.movingElement && !_vm.canvas.isHexagonal
            ? _c("geometry-rect", {
                attrs: {
                  _style: {
                    "fill-r": 1,
                    "fill-cx": 0.1,
                    "fill-cy": 0.1,
                    "stroke-width": 0,
                    stroke: "#F1A746",
                    fill: "#F1A746",
                    "fill-opacity": 0.5,
                    r: "1",
                    "z-index": "998",
                  },
                },
              })
            : _vm._e(),
          !_vm.movingElement && !_vm.canvas.isHexagonal
            ? _c("geometry-rect", {
                attrs: {
                  _style: {
                    "fill-r": 1,
                    "fill-cx": 0.1,
                    "fill-cy": 0.1,
                    "stroke-width": 1.4,
                    stroke: "#F1A746",
                    fill: "#F1A746",
                    "fill-opacity": 1,
                    r: "1",
                    "z-index": "998",
                  },
                },
              })
            : _vm._e(),
          !_vm.canvas.isHexagonal
            ? _c(
                "sub-elements",
                [
                  _c("multi-user-status-indicator", {
                    attrs: {
                      images: _vm.newEditUserImg,
                      "element-height": _vm.elementCoordinate.height,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "sub-elements",
            [
              _c("geometry-point", {
                attrs: { coordinate: [95, 5], _style: _vm.statusCompleteStyle },
              }),
              !_vm.canvas.isHexagonal
                ? _c("text-element", {
                    attrs: {
                      "sub-width": "100%",
                      "sub-height": _vm.titleH,
                      "sub-top": 0,
                      "sub-left": 0,
                      text: _vm.value.classReference
                        ? _vm.value.classReference
                        : "<< Event >>",
                    },
                  })
                : _vm._e(),
              _vm.getFieldDescriptors && !_vm.canvas.isHexagonal
                ? _c("text-element", {
                    attrs: {
                      "sub-width": "100%",
                      "sub-height": _vm.subjectHeight,
                      "sub-top": _vm.subjectTop,
                      "sub-left": 0,
                      subStyle: { "font-size": "16px", "font-weight": "bold" },
                      text: _vm.getNamePanel,
                    },
                  })
                : _vm._e(),
              _vm.getFieldDescriptors && !_vm.canvas.isHexagonal
                ? _c("text-element", {
                    staticClass: "discStyle",
                    attrs: {
                      "sub-width": "120%",
                      "sub-height": _vm.detailHeight,
                      "sub-top": _vm.detailTop,
                      "sub-left": _vm.detailLeft,
                      subStyle: { "font-size": "12px", "text-anchor": "start" },
                      text: _vm.getFieldDescriptors,
                    },
                  })
                : _vm._e(),
              _vm.showValidation
                ? _c("image-element", {
                    attrs: {
                      image: _vm.showValidationImg,
                      "sub-width": "20px",
                      "sub-height": "20px",
                      "sub-right": "5px",
                      "sub-bottom": "5px",
                    },
                  })
                : _vm._e(),
              _vm.value.mirrorElement
                ? _c("image-element", {
                    attrs: {
                      image: _vm.isProjectConnecting
                        ? _vm.link_image
                        : _vm.link_off_image,
                      "sub-width": "10px",
                      "sub-height": "10px",
                      "sub-left": "3px",
                      "sub-top": "3px",
                    },
                  })
                : _vm._e(),
              !_vm.isPBCModel
                ? _c("storming-sub-controller", {
                    attrs: {
                      type: _vm.value._type,
                      value: _vm.value,
                      isReadOnly: !_vm.isEditElement,
                      isHexagonal: _vm.canvas.isHexagonal,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.canvas.isHexagonal
        ? _c("rectangle-element", {
            attrs: {
              connectable: "",
              selectable: _vm.selectable,
              x: _vm.elementCoordinate.x - _vm.elementCoordinate.subWidth * 0.5,
              y: _vm.elementCoordinate.y,
              width: _vm.elementCoordinate.subWidth,
              height: 10,
              _style: {
                stroke: "#F1A746",
                fill: "#F1A746",
                "fill-opacity": 1,
                "z-index": "998",
              },
            },
            on: {
              "update:x": function ($event) {
                return _vm.$set(
                  _vm.elementCoordinate.x - _vm.elementCoordinate.subWidth * 0,
                  "5",
                  $event
                )
              },
              "update:y": function ($event) {
                return _vm.$set(_vm.elementCoordinate, "y", $event)
              },
              customMoveAction: _vm.delayedMove,
              moveShape: _vm.onMoveShape,
              removeShape: _vm.onRemoveShape,
              dblclick: _vm.openPanel,
              selectShape: _vm.selectedActivity,
              deSelectShape: _vm.deSelectedActivity,
              rotateShape: _vm.onRotateShape,
              addedToGroup: _vm.onAddedToGroup,
            },
          })
        : _vm._e(),
      _vm.propertyPanel
        ? _c("domain-event-definition-panel", {
            attrs: {
              isReadOnly: !_vm.isEditElement,
              newEditUserImg: _vm.newEditUserImg,
              image: _vm.image,
              validationLists: _vm.filteredElementValidationResults,
              isPBCModel: _vm.isPBCModel,
            },
            on: {
              close: _vm.closePanel,
              changedPanelValue: _vm.changedPanelValue,
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "geometry-element",
        {
          attrs: {
            selectable: "",
            movable: "",
            resizable: "",
            connectable: "",
            deletable: "",
            id: _vm.value.elementView.id,
            x: _vm.value.elementView.x,
            y: _vm.value.elementView.y,
            width: _vm.value.elementView.width,
            height: _vm.value.elementView.height,
            height:
              _vm.titleH +
              (_vm.value.fieldDescriptors
                ? _vm.value.fieldDescriptors.length * _vm.itemH
                : 0),
          },
          on: {
            "update:id": function ($event) {
              return _vm.$set(_vm.value.elementView, "id", $event)
            },
            "update:x": function ($event) {
              return _vm.$set(_vm.value.elementView, "x", $event)
            },
            "update:y": function ($event) {
              return _vm.$set(_vm.value.elementView, "y", $event)
            },
            "update:width": function ($event) {
              return _vm.$set(_vm.value.elementView, "width", $event)
            },
            "update:height": function ($event) {
              return _vm.$set(_vm.value.elementView, "height", $event)
            },
            dblclick: function ($event) {},
          },
        },
        [
          _c("geometry-rect", {
            attrs: {
              _style: {
                "fill-r": 1,
                "fill-cx": 0.1,
                "fill-cy": 0.1,
                "stroke-width": 1.4,
                fill: "#FFFFFF",
                "fill-opacity": 0,
                r: "1",
              },
            },
          }),
          _c(
            "sub-elements",
            [
              _c("text-element", {
                attrs: {
                  "sub-width": "100%",
                  "sub-height": _vm.titleH / 2,
                  "sub-top": 0,
                  "sub-left": 0,
                  "sub-style": { "font-weight": "bold" },
                  text: _vm.value.classReference
                    ? _vm.value.classReference
                    : _vm.value.name,
                },
              }),
              _c("edge-element", {
                attrs: {
                  vertices: [
                    [0, _vm.titleH - (_vm.value.classReference ? 10 : 0)],
                    ["100%", _vm.titleH - (_vm.value.classReference ? 10 : 0)],
                  ],
                  label: "",
                  "sub-style": {
                    "arrow-start": "none",
                    "stroke-width": "0.5",
                    "arrow-end": "none",
                  },
                },
              }),
              _vm._l(_vm.value.fieldDescriptors, function (item, index) {
                return _vm.value.fieldDescriptors.length > 0
                  ? _c("text-element", {
                      attrs: {
                        "sub-width": "90%",
                        "sub-height": "30%",
                        "sub-top": _vm.titleH + index * _vm.itemH,
                        "sub-style": {
                          "text-anchor": "start",
                          "font-size": 15,
                        },
                        text: "+" + item.name + ": " + item.type,
                      },
                    })
                  : _vm._e()
              }),
              _c("storming-sub-controller", {
                attrs: { type: _vm.value._type, value: _vm.value },
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
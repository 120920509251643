import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: _vm.rerenderRulePanelKey },
    [
      _c(
        "div",
        { staticStyle: { width: "100%", margin: "10px" } },
        [
          _c(
            VBtn,
            {
              attrs: { icon: "" },
              nativeOn: {
                click: function ($event) {
                  return _vm.left()
                },
              },
            },
            [
              _c(VIcon, { attrs: { color: "grey lighten-1" } }, [
                _vm._v("mdi-arrow-left"),
              ]),
            ],
            1
          ),
          _c("b", [_vm._v(_vm._s(_vm.copyValue.name) + "/Rules")]),
          _c(
            VBtn,
            {
              staticStyle: { float: "right", "margin-right": "12px" },
              attrs: { icon: "" },
              nativeOn: {
                click: function ($event) {
                  return _vm.closePanel()
                },
              },
            },
            [
              _c(VIcon, { attrs: { color: "grey lighten-1" } }, [
                _vm._v("mdi-close"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(VDivider),
      _vm._l(_vm.copyValue.aggregateRules, function (rule, idx) {
        return _c(
          VCard,
          { key: idx, staticStyle: { "margin-bottom": "15px" } },
          [
            _c(
              "div",
              { key: _vm.updateRulePanel },
              [
                _c(VCardTitle, [
                  _c(
                    "div",
                    { staticStyle: { width: "100%" } },
                    [
                      _c(
                        VBtn,
                        {
                          staticStyle: { float: "right", margin: "-15px" },
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.deleteAggregateRule(idx)
                            },
                          },
                        },
                        [_c(VIcon, [_vm._v("mdi-close")])],
                        1
                      ),
                      _c(VTextField, {
                        attrs: {
                          rules: [_vm.ruleRequired],
                          label: "Rule Name",
                        },
                        model: {
                          value: rule.ruleName,
                          callback: function ($$v) {
                            _vm.$set(rule, "ruleName", $$v)
                          },
                          expression: "rule.ruleName",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticStyle: { margin: "15px" } },
                  [
                    _c(VAutocomplete, {
                      attrs: {
                        items: _vm.givenItems,
                        "item-text": "name",
                        dense: "",
                        "return-object": "",
                        label: "Given",
                      },
                      model: {
                        value: rule.givenValue,
                        callback: function ($$v) {
                          _vm.$set(rule, "givenValue", $$v)
                        },
                        expression: "rule.givenValue",
                      },
                    }),
                    _c(VAutocomplete, {
                      attrs: {
                        items: rule.whenItems,
                        "item-text": "name",
                        dense: "",
                        "return-object": "",
                        label: "When",
                      },
                      model: {
                        value: rule.whenValue,
                        callback: function ($$v) {
                          _vm.$set(rule, "whenValue", $$v)
                        },
                        expression: "rule.whenValue",
                      },
                    }),
                    _c(VAutocomplete, {
                      attrs: {
                        items: rule.thenItems,
                        "item-text": "name",
                        dense: "",
                        "return-object": "",
                        multiple: "",
                        label: "Then",
                      },
                      model: {
                        value: rule.thenValue,
                        callback: function ($$v) {
                          _vm.$set(rule, "thenValue", $$v)
                        },
                        expression: "rule.thenValue",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              VCardActions,
              [
                _c(VSpacer),
                rule.givenValue || rule.thenValue
                  ? _c(
                      VBtn,
                      {
                        attrs: { color: "primary", text: "", small: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.openExampleDialog(rule)
                          },
                        },
                      },
                      [_vm._v("\n                Examples\n            ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "div",
        { staticStyle: { width: "100%", "margin-bottom": "15px" } },
        [
          _c(
            VTooltip,
            {
              attrs: { right: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        VBtn,
                        _vm._g(
                          _vm._b(
                            {
                              attrs: { icon: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.addAggregateRule()
                                },
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(VIcon, { attrs: { color: "grey lighten-1" } }, [
                            _vm._v("mdi-plus"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Add New Rule")])]
          ),
        ],
        1
      ),
      _vm.isOpenRules
        ? _c(
            VDialog,
            {
              attrs: { width: "100%" },
              model: {
                value: _vm.isOpenRules,
                callback: function ($$v) {
                  _vm.isOpenRules = $$v
                },
                expression: "isOpenRules",
              },
            },
            [
              _c(
                VCard,
                [
                  _c(
                    "table",
                    [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "999" } }, [
                          _vm._v(_vm._s(_vm.openRules.ruleName)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { attrs: { colspan: _vm.givenAttLength } }, [
                          _vm._v("Given"),
                        ]),
                        _c("td", { attrs: { colspan: _vm.whenAttLength } }, [
                          _vm._v("When"),
                        ]),
                        _c("td", { attrs: { colspan: "999" } }, [
                          _vm._v("Then"),
                        ]),
                      ]),
                      _c(
                        "tr",
                        [
                          _c("td", { attrs: { colspan: _vm.givenAttLength } }, [
                            _vm._v(_vm._s(_vm.ruleExampleTableHeaders.given)),
                          ]),
                          _c("td", { attrs: { colspan: _vm.whenAttLength } }, [
                            _vm._v(_vm._s(_vm.ruleExampleTableHeaders.when)),
                          ]),
                          _vm._l(
                            _vm.ruleExampleTableHeaders.then,
                            function (row) {
                              return _c(
                                "td",
                                { attrs: { colspan: _vm.thenAttLength[row] } },
                                [_vm._v(_vm._s(row))]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "tr",
                        [
                          _vm._l(
                            _vm.openRules.attributes[0]["given"],
                            function (att) {
                              return _c("td", [_vm._v(_vm._s(att.name))])
                            }
                          ),
                          _vm._l(
                            _vm.openRules.attributes[0]["when"],
                            function (att) {
                              return _c("td", [_vm._v(_vm._s(att.name))])
                            }
                          ),
                          _vm._l(
                            _vm.openRules.attributes[0]["then"],
                            function (attribute) {
                              return _vm._l(attribute, function (arr) {
                                return _c("td", [_vm._v(_vm._s(arr.name))])
                              })
                            }
                          ),
                        ],
                        2
                      ),
                      _vm._l(_vm.openRules.attributes, function (attributes) {
                        return _c(
                          "tr",
                          [
                            _vm._l(attributes["given"], function (att) {
                              return _c("td", [_vm._v(_vm._s(att.value))])
                            }),
                            _vm._l(attributes["when"], function (att) {
                              return _c("td", [_vm._v(_vm._s(att.value))])
                            }),
                            _vm._l(attributes["then"], function (attribute) {
                              return _vm._l(attribute, function (arr) {
                                return _c("td", [_vm._v(_vm._s(arr.value))])
                              })
                            }),
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  ),
                  _c(VTextField, {
                    model: {
                      value: _vm.openRules.prompt,
                      callback: function ($$v) {
                        _vm.$set(_vm.openRules, "prompt", $$v)
                      },
                      expression: "openRules.prompt",
                    },
                  }),
                  _c(
                    VBtn,
                    {
                      on: {
                        click: function ($event) {
                          return _vm.startExampleGenerate(_vm.openRules)
                        },
                      },
                    },
                    [_vm._v("example generate")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.copyInformation.projectId
    ? _c(
        VCard,
        {
          staticClass: "mx-auto",
          attrs: { outlined: "", "max-width": "800", "max-height": "500" },
        },
        [
          _c(
            "div",
            { key: _vm.renderKey },
            [
              _c(
                VImg,
                {
                  staticStyle: { cursor: "pointer" },
                  attrs: {
                    src: _vm.copyInformation.img,
                    "max-width": "800",
                    "max-height": "300",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectModel()
                    },
                  },
                },
                [
                  _vm._t("chips", function () {
                    return [
                      _c(
                        VRow,
                        {
                          staticStyle: {
                            "margin-right": "1px",
                            "margin-top": "0px",
                          },
                          attrs: { justify: "end" },
                        },
                        [
                          _vm.copyInformation.isNewProject
                            ? _c(
                                VChip,
                                {
                                  staticClass: "ma-2",
                                  staticStyle: {
                                    "margin-right": "10px",
                                    width: "auto",
                                    height: "20px",
                                    "font-size": "10px",
                                    "font-weight": "bold",
                                  },
                                  attrs: {
                                    color: "red",
                                    "text-color": "white",
                                    small: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        NEW\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.copyInformation.chip
                            ? _c(
                                VChip,
                                {
                                  staticClass: "ma-2",
                                  staticStyle: {
                                    width: "auto",
                                    height: "20px",
                                    "font-size": "10px",
                                    "font-weight": "bold",
                                  },
                                  attrs: {
                                    color: _vm.copyInformation.chip.color,
                                    "text-color": "white",
                                    small: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.copyInformation.chip.display) +
                                      "\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            VListItem,
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { "three-line": "" },
            },
            [
              _c(
                VListItemContent,
                {
                  staticStyle: {
                    "max-width": "18%",
                    "margin-left": "1%",
                    "margin-right": "3%",
                  },
                },
                [
                  _c("div", { staticStyle: { "text-align": "center" } }, [
                    _c(
                      "div",
                      { staticStyle: { cursor: "pointer" } },
                      [
                        _c(VAvatar, { attrs: { size: "40" } }, [
                          _vm.copyInformation.authorProfile
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.copyInformation.authorProfile,
                                    },
                                  }),
                                ]
                              )
                            : _c(
                                "div",
                                [
                                  _c(VIcon, { attrs: { "x-large": "" } }, [
                                    _vm._v("mdi-account-circle"),
                                  ]),
                                ],
                                1
                              ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          "font-size": "11px",
                          "margin-top": "5px",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.copyInformation.hiddenEmail) +
                            "\n                "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  style: _vm.isDelete
                    ? "text-decoration-line: line-through;"
                    : "",
                },
                [
                  _c(
                    VCardTitle,
                    { staticStyle: { margin: "-10px 0 -10px" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.copyInformation.projectName) +
                          "\n            "
                      ),
                    ]
                  ),
                  _c(
                    VCardText,
                    { staticStyle: { color: "rgba(0, 0, 0, 0.6)" } },
                    [
                      _vm._v(
                        "\n                LastModified Date : " +
                          _vm._s(_vm.copyInformation.lastModifiedTimeStamp)
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                Created Date : " +
                          _vm._s(_vm.copyInformation.createdTimeStamp)
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                Comment : " +
                          _vm._s(_vm.copyInformation.comment)
                      ),
                      _c("br"),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VCardActions,
            [
              _vm._t(
                "action",
                function () {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          bottom: "5px",
                          right: "5px",
                        },
                      },
                      [
                        _vm.loading
                          ? _c(
                              "div",
                              [
                                _c(VProgressCircular, {
                                  attrs: { indeterminate: "", size: "24" },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  VRow,
                                  { staticStyle: { "align-items": "center" } },
                                  [
                                    _c(
                                      VBtn,
                                      {
                                        attrs: { text: "", color: "#1E88E5" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectModel()
                                          },
                                        },
                                      },
                                      [_vm._v("Select")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                      ]
                    ),
                  ]
                },
                { project: _vm.copyInformation }
              ),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }